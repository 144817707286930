export const SettingsIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.9987 10.8333C10.9192 10.8333 11.6654 10.0871 11.6654 9.16667C11.6654 8.24619 10.9192 7.5 9.9987 7.5C9.07822 7.5 8.33203 8.24619 8.33203 9.16667C8.33203 10.0871 9.07822 10.8333 9.9987 10.8333Z"
      stroke="#999999"
      strokeWidth="1.66667"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M16.6649 9.9987C16.6641 9.51862 16.6115 9.04003 16.5082 8.5712L18.7699 6.8587L17.1032 3.97203L14.4841 5.07703C13.7748 4.42672 12.933 3.93798 12.0166 3.64453L11.6649 0.832031H8.33156L7.9799 3.64453C7.06348 3.93798 6.22163 4.42672 5.5124 5.07703L2.89323 3.97203L1.22656 6.8587L3.48823 8.5712C3.27906 9.51137 3.27906 10.486 3.48823 11.4262L1.22656 13.1387L2.89323 16.0254L5.5124 14.9204C6.22163 15.5707 7.06348 16.0594 7.9799 16.3529L8.33156 19.1654H11.6649L12.0166 16.3529C12.933 16.0594 13.7748 15.5707 14.4841 14.9204L17.1032 16.0254L18.7699 13.1387L16.5082 11.4262C16.6115 10.9574 16.6641 10.4788 16.6649 9.9987Z"
      stroke="#999999"
      strokeWidth="1.66667"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path d="M10 10.832V12.4987" stroke="#999999" strokeWidth="1.66667" strokeMiterlimit="10" strokeLinecap="square" />
  </svg>
);
