import { NewEntryField, NewEntryFieldTypes } from "../../types/newEntry";
import { Project } from "../../types/project";
import { CreateTransactionFields, CreateTransactionFormValues } from "./types";

export const initialValuesCreateTransaction = {
  [CreateTransactionFields.project]: [],
  [CreateTransactionFields.url]: "",
  [CreateTransactionFields.webhookName]: "",
} as CreateTransactionFormValues;

export const getCreateTransactionFields = (projects: Project[]): NewEntryField[] => [
  {
    type: NewEntryFieldTypes.select,
    name: CreateTransactionFields.project,
    label: "Select project",
    placeholder: "Select project name",
    options: projects.map(({ name }) => name),
  },
  {
    type: NewEntryFieldTypes.text,
    name: CreateTransactionFields.webhookName,
    label: "Webhook name",
    placeholder: "Enter webhook name",
  },
  {
    type: NewEntryFieldTypes.text,
    name: CreateTransactionFields.url,
    label: "URL",
    placeholder: "https://charlesthegreat.com/arpeggio",
  },
];
