import { Network } from "../../types/project";

export interface CreateProjectFormValues {
  name: string;
  network: Network[];
}

export enum CreateProjectFields {
  name = "name",
  network = "network",
}
