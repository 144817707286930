import React, { useEffect, useRef, useState } from "react";
import { styled, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Button, Chip, Loader, TextField } from "@maestro-org/ui-kit";

import { getAccountDetailsFields, initialValuesAccountDetails } from "../../../forms/accountDetails/form";
import { AccountDetailsFields, AccountDetailsFormValues } from "../../../forms/accountDetails/types";
import { accountDetailsSchema } from "../../../forms/accountDetails/validation";

import {
  getAuth0FuncData,
  getAuthData,
  getIsUpdatingUserInfo,
  getIsUploadingImage,
  getSubscriptionPlan,
} from "../../../redux/reducers/authReducer";
import { setIsUpdatingUserInfo, uploadImageServer } from "../../../redux/actions/authActions";

import { LoginType } from "../../../types/auth";
import { SubscriptionPlan } from "../../../types/subscription";

import Divider from "../../../components/Divider/Divider";
import UserImage from "../../../components/UserImage/UserImage";
import PlanChip from "../../../components/Chip/PlanChip";
import SettingsCard from "./SettingsCard";
import { GithubIcon, GoogleIcon } from "../../../components/Icons";

import { useUserMetadata } from "../../../hooks/useUserMetadata";

const AccountDetails = () => {
  const userData = useSelector(getAuthData);
  const auth0Data = useSelector(getAuth0FuncData);
  const isUploadingImage = useSelector(getIsUploadingImage);
  const isUpdatingUserInfo = useSelector(getIsUpdatingUserInfo);
  const subscriptionPlan = useSelector(getSubscriptionPlan);

  const fileInput = useRef(null);

  const handleClick = () => {
    if (!fileInput.current) return;
    (fileInput.current as HTMLElement).click();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files;
    if (!fileUploaded?.length) return;
    const formData = new FormData();
    formData.append("key", `${process.env.REACT_APP_IMAGE_HOST_KEY}`);
    formData.append("image", fileUploaded[0]);
    dispatch(uploadImageServer(formData));
  };

  const dispatch = useDispatch();

  const { updateUserInfo } = useUserMetadata();

  const onSubmit = (values: AccountDetailsFormValues) => {
    updateUserInfo(values, true);
    dispatch(setIsUpdatingUserInfo({ status: true }));
  };

  const handleCancelClick = () => {
    setFieldValue("firstName", userData.firstName);
    setFieldValue("lastName", userData.lastName);
  };

  const handleDeletePicture = () => {
    updateUserInfo({ picture: "" }, true);
  };

  const { values, touched, errors, handleChange, handleBlur, setFieldValue, handleSubmit } = useFormik({
    initialValues: initialValuesAccountDetails,
    validationSchema: accountDetailsSchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit,
  });

  const checkError = (name: string) => !!errors[name as keyof typeof errors] && touched[name as keyof typeof touched];

  const getHelperText = (name: string) =>
    errors[name as keyof typeof errors] && touched[name as keyof typeof touched]
      ? errors[name as keyof typeof errors]
      : undefined;

  const getEndEmailAdornment = () => {
    if (auth0Data.loginType === LoginType.Regular) return;
    return (
      <EndEmailAdornment>
        {auth0Data.loginType === LoginType.Google ? (
          <GoogleIcon />
        ) : (
          <DarkIconWrapper>
            <GithubIcon />
          </DarkIconWrapper>
        )}
        <DesktopOnly>
          <Typography variant="paragraphSmall" color="grey.A200">{`Connected with ${
            auth0Data.loginType === LoginType.Google ? "Google" : "GitHub"
          }`}</Typography>
        </DesktopOnly>
      </EndEmailAdornment>
    );
  };

  useEffect(() => {
    if (!userData) return;
    setFieldValue(AccountDetailsFields.firstName, userData.firstName);
    setFieldValue(AccountDetailsFields.lastName, userData.lastName);
    setFieldValue(AccountDetailsFields.email, userData.email);
  }, [userData]);

  const changed =
    userData.firstName !== values.firstName || userData.lastName !== values.lastName || userData.email !== values.email;

  return (
    <form onSubmit={handleSubmit}>
      <SettingsCard>
        <Typography color="grey.A200" variant="h6">
          User profile
        </Typography>
        <UserDetailsWrapper>
          <UserDetails>
            <UserImage size={80} />
            <NameAndPlanWrapper>
              <Typography color="grey.A200" variant="h5">
                {`${userData.firstName} ${userData.lastName}`}
              </Typography>
              <div>
                <PlanChip plan={subscriptionPlan} />
              </div>
            </NameAndPlanWrapper>
          </UserDetails>
          {/* <PictureActions>
            <Button size="xsmall" onClick={handleClick}>
              {isUploadingImage ? <Loader color="secondary" size={25} /> : "Edit profile picture"}
            </Button>
            <DeleteButton size="xsmall" onClick={handleDeletePicture}>
              Delete
            </DeleteButton>
          </PictureActions>
          <input type="file" hidden ref={fileInput} onChange={handleInputChange} /> */}
        </UserDetailsWrapper>
        <Divider />
        <FormFieldsWrapper>
          {getAccountDetailsFields.map((field) => (
            <FoormFieldWrapper key={field.name} fullWidth={field.fullWidth}>
              <TextField
                name={field.name}
                type={field.type}
                variant="outlined"
                value={values[field.name]}
                onChange={handleChange}
                onBlur={handleBlur}
                label={field.label}
                placeholder={field.placeholder}
                errorPosition={field.fullWidth ? "bottom" : "top"}
                error={checkError(field.name)}
                helperText={getHelperText(field.name)}
                disabled={field.name === AccountDetailsFields.email}
                endIcon={field.name === AccountDetailsFields.email && getEndEmailAdornment()}
              />
            </FoormFieldWrapper>
          ))}
        </FormFieldsWrapper>
        {changed && (
          <Actions>
            <CancelButton onClick={handleCancelClick} variant="secondary" onMouseDown={(e) => e.preventDefault()}>
              <Typography color="grey.A200">Cancel</Typography>
            </CancelButton>
            <Button type="submit" onMouseDown={(e) => e.preventDefault()}>
              {isUpdatingUserInfo ? <Loader color="secondary" size={25} /> : <Typography>Save</Typography>}
            </Button>
          </Actions>
        )}
      </SettingsCard>
    </form>
  );
};

const UserDetailsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    rowGap: "32px",
  },
}));

const UserDetails = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  alignItems: "center",
  columnGap: "16px",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    rowGap: "16px",
  },
}));

const PictureActions = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "8px",
});

const DeleteButton = styled(Button)(({ theme }) => ({
  background: theme.palette.grey[50],
  color: theme.palette.grey.A200,

  "&:hover": {
    background: theme.palette.grey[50],
    color: theme.palette.grey.A200,
  },
}));

const NameAndPlanWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",

  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
  },
}));

const FormFieldsWrapper = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  rowGap: "20px",
});

const FoormFieldWrapper = styled("div")<{ fullWidth: boolean }>(({ fullWidth, theme }) => ({
  flexBasis: fullWidth ? "100%" : "calc(50% - 18px)",

  [theme.breakpoints.down("sm")]: {
    flexBasis: "100%",
  },
}));

const Actions = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "16px",
});

const CancelButton = styled(Button)(({ theme }) => ({
  background: theme.palette.grey["50"],

  "&:hover": {
    background: theme.palette.grey["50"],
  },
}));

const EndEmailAdornment = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "8px",
});

const DarkIconWrapper = styled("div")(({ theme }) => ({
  "& svg": {
    fill: theme.palette.common.black,
  },
}));

const DesktopOnly = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

export default AccountDetails;
