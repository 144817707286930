import { Chip, Radio } from "@maestro-org/ui-kit";
import { styled, Typography } from "@mui/material";

import { SubscriptionPlan } from "../../../types/subscription";

import Divider from "../../../components/Divider/Divider";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { ArtistIcon, ComposerIcon, ConductorIcon, InfoIcon, VirtuosoIcon } from "../../../components/Icons";

import { numberDecorator } from "../../../lib/numberDecorator";
import { UserSubscriptions } from "../../../types/user";

interface PackageProps extends UserSubscriptions {
  isMostPopular: boolean;
  isActive: boolean;
  handleChangeActive: (id: string) => void;
}

const getPlanLabel = {
  [SubscriptionPlan.artist]: "Artist",
  [SubscriptionPlan.composer]: "Composer",
  [SubscriptionPlan.conductor]: "Conductor",
  [SubscriptionPlan.virtuoso]: "Virtuoso",
};

const getIcon = {
  [SubscriptionPlan.artist]: <ArtistIcon />,
  [SubscriptionPlan.composer]: <ComposerIcon />,
  [SubscriptionPlan.conductor]: <ConductorIcon />,
  [SubscriptionPlan.virtuoso]: <VirtuosoIcon />,
};

const PlanPackage = ({
  isActive,
  subscription_name,
  credits_description,
  price_per_credit,
  monthly_fee,
  services,
  id,
  isMostPopular,
  handleChangeActive,
}: PackageProps) => {
  return (
    <PackageWrapper onClick={() => handleChangeActive(id)} isActive={isActive}>
      {isMostPopular && <MostPopular color="custom" hexColor="#C53DD8" label="Most popular" />}
      <Top>
        <TitleWrapper>
          <WithIcon>
            {getIcon[subscription_name]}
            <Typography color="grey.A200" variant="paragraphMedium">
              {getPlanLabel[subscription_name]}
            </Typography>
          </WithIcon>
          <StyledRadio checked={isActive} value="" label="" handleChange={console.log} />
        </TitleWrapper>
        <Price color={subscription_name === SubscriptionPlan.virtuoso ? "primary.main" : "grey.A200"} variant="h5">
          {subscription_name === SubscriptionPlan.virtuoso
            ? "Contact us"
            : +price_per_credit
            ? `$${+price_per_credit}/credit`
            : +monthly_fee
            ? `$${+monthly_fee}/mo`
            : "Free"}
        </Price>
        <CreditsWrapper>
          <Typography color="grey.500" variant="paragraphMedium">
            {credits_description}
          </Typography>
          <StyledTooltip
            title={
              <Typography variant="article" color="grey.800">
                Compute credits are proportional to resources used. <LearnMoreRef>Learn more</LearnMoreRef>
              </Typography>
            }
          >
            <InfoIconWrapper>
              <InfoIcon />
            </InfoIconWrapper>
          </StyledTooltip>
        </CreditsWrapper>
      </Top>
      <div>
        <PackageDividerWrapper>
          <Divider />
        </PackageDividerWrapper>
        <PackageDetailsWrapper>
          {services.split("\\n\\n").map((serviceItem: string) => {
            const service = serviceItem.split("\\n");
            if (!service.length) return <div />;
            return (
              <ItemWrapper key={service[0]}>
                <Typography color="grey.A200" variant="paragraphSmall">
                  ✓
                </Typography>
                <PackageDetailsItem>
                  <Typography color="grey.A200" variant="paragraphSmall">
                    {service[0]}
                  </Typography>
                  {service.slice(1).map((item) => (
                    <Typography key={item} color="grey.500" variant="paragraphSmall">
                      {item}
                    </Typography>
                  ))}
                </PackageDetailsItem>
              </ItemWrapper>
            );
          })}
        </PackageDetailsWrapper>
      </div>
    </PackageWrapper>
  );
};

const PackageWrapper = styled("div")<{ isActive: boolean }>(({ theme, isActive }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  padding: "32px",
  borderRadius: theme.borderRadius.sm,
  boxShadow: "0px 2px 12px rgba(153, 153, 153, 0.12)",
  border: isActive ? `2px solid ${theme.palette.primary.main}` : `2px solid ${theme.palette.grey[100]}`,
  background: theme.palette.common.white,
  minWidth: "336px",
  cursor: "pointer",

  "&:hover": {
    border: `2px solid ${theme.palette.primary.main}`,
  },

  [theme.breakpoints.down(1101)]: {
    minWidth: "280px",
  },
}));

const MostPopular = styled(Chip)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "8px 20px",
  ...theme.typography.article,
}));

const Top = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "12px",
});

const CreditsWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "8px",
});

const InfoIconWrapper = styled("div")({
  height: "15px",
});

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    maxWidth: "236px",
    padding: "8px 16px",
    boxShadow: "0px 2px 16px -2px rgba(15, 3, 17, 0.16)",
    border: `1px solid ${theme.palette.grey[100]}`,
  },
}));

const LearnMoreRef = styled("a")(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: "pointer",
}));

const TitleWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  "& .MuiFormControlLabel-root": {
    margin: 0,
  },
});

const WithIcon = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "8px",
});

const Price = styled(Typography)({
  fontWeight: 500,
});

const PackageDividerWrapper = styled("div")({
  padding: "20px 0",
});

const PackageDetailsWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "12px",
  // height: "150px",
});

const PackageDetailsItem = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const ItemWrapper = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  columnGap: "5px",
});

const StyledRadio = styled(Radio)({
  padding: 0,
  cursor: "default",
});

export default PlanPackage;
