import { LinearProgress, Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCreditUsage, getUser, getUserLoading, getUserSubscriptions } from "../../../redux/reducers/usersReducer";
import { getIsAuthorized, getSubscriptionPlan } from "../../../redux/reducers/authReducer";
import { getUserCreditUsageServer, getUserServer } from "../../../redux/actions/userActions";

import Container from "../../../components/Container/Container";
import { ArtistIcon, ComposerIcon, ConductorIcon, UpgradeIcon, VirtuosoIcon } from "../../../components/Icons";

import { SubscriptionPlan } from "../../../types/subscription";
import { pages } from "../../../lib/routeUtils";
import { numberDecorator } from "../../../lib/numberDecorator";
import { Loader } from "@maestro-org/ui-kit";
import LineChart from "../../../components/LineChart/LineChart";
import {
  getDaysBetweenBilling,
  getDaysUntilNextBilling,
  getEmptyCreditLeftDays,
  getEmptyCreditUsageData,
} from "../../../lib/creditUsage";
import { addDays, format } from "date-fns";

const getIcon = {
  [SubscriptionPlan.artist]: <ArtistIcon />,
  [SubscriptionPlan.composer]: <ComposerIcon />,
  [SubscriptionPlan.conductor]: <ConductorIcon />,
  [SubscriptionPlan.virtuoso]: <VirtuosoIcon />,
};

const compareTwoDates = (date1: string, date2: string) => {
  try {
    return format(new Date(date1), "yy MM dd") === format(new Date(date2), "yy MM dd");
  } catch (error) {
    console.log("ERROR", error);
    console.log("date1", date1);
    console.log("date2", date2);
    return false;
  }
};

const SubscriptionWidget = () => {
  const isAuthorized = useSelector(getIsAuthorized);
  const plan = useSelector(getSubscriptionPlan);
  const loadingUser = useSelector(getUserLoading);
  const creditUsage = useSelector(getCreditUsage);
  const user = useSelector(getUser);
  const allSubscriptions = useSelector(getUserSubscriptions);

  const [lastBillingDate, setLastBillingDate] = useState<Date>();
  const [nextBillingDate, setNextBillingDate] = useState<Date>();

  const dispatch = useDispatch();

  const showCreditsDiagram = plan === SubscriptionPlan.conductor || plan === SubscriptionPlan.virtuoso;

  const allCreditsStr = allSubscriptions.find(
    (subscription) => subscription.subscription_name === plan,
  )?.daily_credit_limit;

  const allCredits = allCreditsStr ? +allCreditsStr || 0 : 0;

  const initialChartData = getEmptyCreditUsageData(lastBillingDate);

  useEffect(() => {
    if (!isAuthorized || !user) return;
    setLastBillingDate(user.last_billing_date ? new Date(user.last_billing_date * 1000) : new Date());
    setNextBillingDate(user.next_billing_date ? new Date(user.next_billing_date * 1000) : new Date());
  }, [isAuthorized, user]);

  useEffect(() => {
    if (!lastBillingDate || !nextBillingDate) return;
    dispatch(getUserCreditUsageServer({ days: initialChartData.length || 1 }));
  }, [lastBillingDate, nextBillingDate]);

  const creditsUsageData = initialChartData.map((emptyItem) => {
    const usageItem = creditUsage.find((usageItem) =>
      compareTwoDates(
        emptyItem.date,
        new Date(usageItem.date).toLocaleDateString("en-US", {
          timeZone: "UTC",
          year: "numeric",
          month: "numeric",
          day: "numeric",
        }),
      ),
    );
    return usageItem
      ? {
          date: format(new Date(usageItem.date), "MM/dd/yyyy h:mm:ss a"),
          value: +usageItem.credits_used || 0,
          emptyValue: false,
        }
      : emptyItem;
  });

  const spentCredits = showCreditsDiagram
    ? creditsUsageData.reduce((acc, item) => acc + item.value, 0)
    : user?.credits_used
    ? +user.credits_used || 0
    : 0;

  const allDaysBetweenBilling = getDaysBetweenBilling(lastBillingDate, nextBillingDate);

  const passedDaysPercent =
    ((allDaysBetweenBilling - getDaysUntilNextBilling(nextBillingDate)) / allDaysBetweenBilling) * 100;

  return (
    <Wrapper>
      <Row>
        <CurrentPlan>
          {getIcon[plan]}
          <PlanLabel color="grey.A200" variant="paragraphMedium">
            {plan}
          </PlanLabel>
        </CurrentPlan>
        <Link to={pages.settings()}>
          <UpgradeWrapper>
            <UpgradeIcon />
            <Typography color="primary.main" variant="paragraphMedium">
              Upgrade
            </Typography>
          </UpgradeWrapper>
        </Link>
      </Row>
      <CreditsDataWrapper>
        {loadingUser ? (
          <Loader />
        ) : showCreditsDiagram ? (
          <CreditsDiagram
            data={creditsUsageData}
            passedDaysPercent={passedDaysPercent}
            spentCredits={spentCredits}
            lastBillingDate={lastBillingDate}
            nextBillingDate={nextBillingDate}
          />
        ) : (
          <CreditsSlider creditsUsed={spentCredits} allCredits={allCredits} />
        )}
      </CreditsDataWrapper>
    </Wrapper>
  );
};

const Wrapper = styled("div")(({ theme }) => ({
  padding: "24px 27px 24px 24px",
  display: "flex",
  flexDirection: "column",
  rowGap: "12px",
  background: theme.palette.common.white,
  borderRadius: "2px",
  // border: `1px solid ${theme.palette.grey[200]}`,
  boxShadow: "0px 8px 30px -2px rgba(0, 0, 0, 0.04)",
  width: "460px",

  [theme.breakpoints.down("lg")]: {
    width: "370px",
  },
}));

const Row = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const CreditsDataWrapper = styled("div")(({ theme }) => ({}));

const CurrentPlan = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "4px",

  "& svg": {
    width: "20px",
    height: "17px",
  },
});

const PlanLabel = styled(Typography)({
  textTransform: "capitalize",
});

const UpgradeWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "8px",
});

interface CreditsSliderProps {
  creditsUsed: number;
  allCredits: number;
}

const CreditsSlider = ({ creditsUsed, allCredits }: CreditsSliderProps) => {
  const availableCredits = allCredits - creditsUsed;

  return (
    <CreditsWrapper>
      <Row>
        <Typography variant="paragraphMedium" color="grey.A200">
          {numberDecorator(availableCredits)} credits remaining
        </Typography>
        <Typography variant="paragraphMedium" color="grey.A200">
          {Math.floor((availableCredits / allCredits) * 100) || 0}%
        </Typography>
      </Row>
      <RemainingCreditsBar variant="determinate" value={Math.floor((availableCredits / allCredits) * 100) || 0} />
    </CreditsWrapper>
  );
};

const CreditsWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "16px",
});

const RemainingCreditsBar = styled(LinearProgress)(({ theme }) => ({
  height: "8px",
  background: theme.palette.grey[100],
  borderRadius: "26px",

  "& .MuiLinearProgress-bar": {
    borderRadius: "8px",
  },
}));

interface CreditsDiagramProps {
  data: {
    date: string;
    value: number;
  }[];
  passedDaysPercent: number;
  spentCredits: number;
  lastBillingDate?: Date;
  nextBillingDate?: Date;
}

const CreditsDiagram = ({ data, passedDaysPercent, spentCredits, nextBillingDate }: CreditsDiagramProps) => {
  const daysLeft = getDaysUntilNextBilling(nextBillingDate);

  return (
    <CreditsDiagramWrapper>
      <Row>
        <Typography variant="paragraphMedium" color="grey.A200">
          {numberDecorator(spentCredits)} credits spent
        </Typography>
        <Typography variant="paragraphMedium" color="grey.A200">
          {daysLeft} days left
        </Typography>
      </Row>
      <ChartsWrapper>
        <LineChartWrapper passedDaysPercent={passedDaysPercent}>
          <LineChart height={22} units="credits" data={data} />
        </LineChartWrapper>
        <LineChartWrapper passedDaysPercent={100 - passedDaysPercent}>
          <RightChartWrapper>
            <LineChart
              height={22}
              units="credits"
              data={getEmptyCreditLeftDays(nextBillingDate)}
              strokeDasharray="3 3"
            />
          </RightChartWrapper>
        </LineChartWrapper>
      </ChartsWrapper>
    </CreditsDiagramWrapper>
  );
};

const CreditsDiagramWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "12px",
});

const ChartsWrapper = styled("div")({
  display: "flex",
});

const LineChartWrapper = styled("div")<{ passedDaysPercent: number }>(({ passedDaysPercent }) => ({
  width: `${passedDaysPercent}%`,
}));

const RightChartWrapper = styled("div")({
  position: "relative",
  right: "10px",
});

export default SubscriptionWidget;
