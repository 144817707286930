import { Loader } from "@maestro-org/ui-kit";
import { addDays, differenceInDays, eachDayOfInterval, format, getTime, startOfDay } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { LinearProgress, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { SubscriptionPlan } from "../../../types/subscription";

import { getCreditUsage, getUser, getUserLoading, getUserSubscriptions } from "../../../redux/reducers/usersReducer";
import { getIsAuthorized, getSubscriptionPlan } from "../../../redux/reducers/authReducer";

import SettingsCard from "./SettingsCard";
import PlanChip from "../../../components/Chip/PlanChip";
import Divider from "../../../components/Divider/Divider";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { InfoIcon, UpgradeIcon } from "../../../components/Icons";

import { pages } from "../../../lib/routeUtils";
import { numberDecorator } from "../../../lib/numberDecorator";
import LineChart from "../../../components/LineChart/LineChart";
import { CreditsUsageItem } from "../../../types/user";

import {
  getDaysBetweenBilling,
  getDaysUntilNextBilling,
  getEmptyCreditLeftDays,
  getEmptyCreditUsageData,
} from "../../../lib/creditUsage";
import {
  getUserCreditUsageServer,
  getUserServer,
  getUserSubscriptionsServer,
} from "../../../redux/actions/userActions";

const compareTwoDates = (date1: string, date2: string) => {
  try {
    return format(new Date(date1), "yy MM dd") === format(new Date(date2), "yy MM dd");
  } catch (error) {
    console.log("ERROR", error);
    console.log("date1", date1);
    console.log("date2", date2);
    return false;
  }
};

const CurrentSubscriptionPlan = () => {
  const isAuthorized = useSelector(getIsAuthorized);
  const subscriptionPlan = useSelector(getSubscriptionPlan);
  const loadingUser = useSelector(getUserLoading);
  const user = useSelector(getUser);
  const creditUsage = useSelector(getCreditUsage);
  const allSubscriptions = useSelector(getUserSubscriptions);
  const plan = useSelector(getSubscriptionPlan);

  const [lastBillingDate, setLastBillingDate] = useState<Date>();
  const [nextBillingDate, setNextBillingDate] = useState<Date>();

  const dispatch = useDispatch();

  const showDiagram = subscriptionPlan === SubscriptionPlan.conductor || subscriptionPlan === SubscriptionPlan.virtuoso;

  const currentPlan = allSubscriptions.find((subscription) => subscription.subscription_name === plan);

  const allCreditsStr = currentPlan?.daily_credit_limit;

  const allCredits = allCreditsStr ? +allCreditsStr || 0 : 0;

  const price = currentPlan
    ? +currentPlan.price_per_credit
      ? `$${+currentPlan.price_per_credit}/credit`
      : `$${+currentPlan.monthly_fee}/mo`
    : `No data`;

  const initialChartData = getEmptyCreditUsageData(lastBillingDate);

  useEffect(() => {
    if (!isAuthorized || !user) return;
    setLastBillingDate(user.last_billing_date ? new Date(user.last_billing_date * 1000) : new Date());
    setNextBillingDate(user.next_billing_date ? new Date(user.next_billing_date * 1000) : new Date());
  }, [isAuthorized, user]);

  useEffect(() => {
    if (!lastBillingDate || !nextBillingDate) return;
    dispatch(getUserCreditUsageServer({ days: initialChartData.length || 1 }));
  }, [lastBillingDate, nextBillingDate]);

  const creditsUsageData = initialChartData.map((emptyItem) => {
    const usageItem = creditUsage.find((usageItem) =>
      compareTwoDates(
        emptyItem.date,
        new Date(usageItem.date).toLocaleDateString("en-US", {
          timeZone: "UTC",
          year: "numeric",
          month: "numeric",
          day: "numeric",
        }),
      ),
    );
    return usageItem
      ? {
          date: format(new Date(usageItem.date), "MM/dd/yyyy h:mm:ss a"),
          value: +usageItem.credits_used || 0,
          emptyValue: false,
        }
      : emptyItem;
  });

  const spentCredits = showDiagram
    ? creditsUsageData.reduce((acc, item) => acc + item.value, 0)
    : user?.credits_used
    ? +user.credits_used || 0
    : 0;

  const passedDaysPercent =
    ((getDaysBetweenBilling(lastBillingDate, nextBillingDate) - getDaysUntilNextBilling(nextBillingDate)) /
      getDaysBetweenBilling(lastBillingDate, nextBillingDate)) *
    100;

  return (
    <SettingsCard>
      <PlanWrapper>
        <Title>
          <DesktopOnly>
            <Typography variant="h6" color="grey.A200">
              Subscription plan
            </Typography>
          </DesktopOnly>
          <MobileOnly>
            <Typography variant="h6" color="grey.A200">
              Subscription
            </Typography>
          </MobileOnly>
          <Link to={pages.settings()}>
            <UpgradeWrapper>
              <UpgradeIcon />
              <Typography variant="paragraphMedium" color="primary.main">
                Upgrade
              </Typography>
            </UpgradeWrapper>
          </Link>
        </Title>
        <PlanChip plan={subscriptionPlan} />
      </PlanWrapper>
      <PlanInfo isArtist={subscriptionPlan === SubscriptionPlan.artist}>
        {subscriptionPlan !== SubscriptionPlan.virtuoso && (
          <PlanInfoItem>
            <Typography color="grey.400" variant="paragraphMedium">
              Price
            </Typography>
            <Typography color="grey.A200" variant="h5">
              {price}
            </Typography>
          </PlanInfoItem>
        )}
        {subscriptionPlan !== SubscriptionPlan.artist && (
          <PlanInfoItem>
            <Typography color="grey.400" variant="paragraphMedium">
              {showDiagram ? "Billing date" : "Renews on"}
            </Typography>
            <Typography color="grey.A200" variant="h5">
              {user?.next_billing_date
                ? format(new Date(user.next_billing_date * 1000), "MMM d, yyyy")
                : "No billing date"}
            </Typography>
          </PlanInfoItem>
        )}
        <PlanInfoItem>
          <WithIcon>
            <Typography color="grey.400" variant="paragraphMedium">
              {showDiagram ? "Credits spent" : "Credits per day"}
            </Typography>
            <StyledTooltip
              title={
                <Typography variant="article" color="grey.800">
                  Compute credits are proportional to resources used.
                </Typography>
              }
            >
              <div>
                <InfoIcon />
              </div>
            </StyledTooltip>
          </WithIcon>
          <Typography color="grey.A200" variant="h5">
            {showDiagram ? numberDecorator(spentCredits) : numberDecorator(allCredits)}
          </Typography>
        </PlanInfoItem>
      </PlanInfo>
      <Divider />
      {loadingUser ? (
        <Loader />
      ) : showDiagram ? (
        <CreditsDiagram
          data={creditsUsageData}
          passedDaysPercent={passedDaysPercent}
          lastBillingDate={lastBillingDate}
          nextBillingDate={nextBillingDate}
        />
      ) : (
        <CreditsSlider creditsUsed={spentCredits} allCredits={allCredits} />
      )}
    </SettingsCard>
  );
};

const DesktopOnly = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const MobileOnly = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

const PlanWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  [theme.breakpoints.down("sm")]: {
    alignItems: "flex-start",
  },
}));

const Title = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "4px",
});

const UpgradeWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "8px",
});

const PlanInfo = styled("div")<{ isArtist: boolean }>(({ theme, isArtist }) => ({
  display: "flex",
  columnGap: "40px",
  alignItems: "center",

  [theme.breakpoints.down("sm")]: {
    ...(!isArtist && {
      flexDirection: "column",
      alignItems: "flex-start",
      rowGap: "24px",
    }),
  },
}));

const PlanInfoItem = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "6px",
});

const WithIcon = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "6px",
});

const CreditsWrapper = styled("div")({
  display: "flex",
  alignItems: "flex-end",
  columnGap: "4px",
});

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    maxWidth: "236px",
    padding: "8px 16px",
    boxShadow: "0px 2px 16px -2px rgba(15, 3, 17, 0.16)",
    border: `1px solid ${theme.palette.grey[100]}`,
    display: "flex",
    alignItems: "center",
    columnGap: "9px",
  },

  "& svg": {
    width: "13px",
    height: "16px",
  },
}));

interface CreditsSliderProps {
  creditsUsed: number;
  allCredits: number;
}

const CreditsSlider = ({ creditsUsed, allCredits }: CreditsSliderProps) => {
  const availableCredits = allCredits - creditsUsed;

  return (
    <RemainingCreditsWrapper>
      <RemainingCreditsValues>
        <Typography color="grey.A200" variant="paragraphMedium">
          {numberDecorator(availableCredits)} credits remaining
        </Typography>
        <Typography color="grey.A200" variant="paragraphMedium">
          {Math.floor((availableCredits / allCredits) * 100) || 0}%
        </Typography>
      </RemainingCreditsValues>
      <RemainingCreditsBar variant="determinate" value={Math.floor((availableCredits / allCredits) * 100) || 0} />
    </RemainingCreditsWrapper>
  );
};

const RemainingCreditsBar = styled(LinearProgress)(({ theme }) => ({
  height: "12px",
  background: theme.palette.grey[100],
  borderRadius: "8px",

  "& .MuiLinearProgress-bar": {
    borderRadius: "8px",
  },
}));

const RemainingCreditsWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "12px",
});

const RemainingCreditsValues = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

interface CreditsDiagramProps {
  data: {
    date: string;
    value: number;
  }[];
  passedDaysPercent: number;
  lastBillingDate?: Date;
  nextBillingDate?: Date;
}

const CreditsDiagram = ({ data, passedDaysPercent, nextBillingDate }: CreditsDiagramProps) => {
  const daysLeft = getDaysUntilNextBilling(nextBillingDate);

  return (
    <CerditsDiagram>
      <CreditsDiagramTopWrapper>
        <Typography color="grey.A200" variant="paragraphMedium">
          Credit usage
        </Typography>
        <Typography color="grey.A200" variant="paragraphMedium">
          {daysLeft} days left
        </Typography>
      </CreditsDiagramTopWrapper>
      <ChartsWrapper>
        <LineChartWrapper passedDaysPercent={passedDaysPercent}>
          <LineChart units="credits" data={data} />
        </LineChartWrapper>
        <LineChartWrapper passedDaysPercent={100 - passedDaysPercent}>
          <RightChartWrapper>
            <LineChart units="credits" data={getEmptyCreditLeftDays(nextBillingDate)} strokeDasharray="3 3" />
          </RightChartWrapper>
        </LineChartWrapper>
      </ChartsWrapper>
    </CerditsDiagram>
  );
};

const CerditsDiagram = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "12px",
});

const CreditsDiagramTopWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const ChartsWrapper = styled("div")({
  display: "flex",
});

const LineChartWrapper = styled("div")<{ passedDaysPercent: number }>(({ passedDaysPercent }) => ({
  width: `${passedDaysPercent}%`,
}));

const RightChartWrapper = styled("div")({
  position: "relative",
  right: "10px",
});

export default CurrentSubscriptionPlan;
