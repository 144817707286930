import React from "react";
import { styled } from "@mui/material";
import { Chip } from "@maestro-org/ui-kit";

import { HistoryItemStatus } from "../../types/invoiceHistory";

const getLabel = {
  [HistoryItemStatus.paid]: "PAID",
  [HistoryItemStatus.failed]: "FAILED",
  [HistoryItemStatus.draft]: "PENDING",
  [HistoryItemStatus.open]: "PENDING",
};

const getBgHexColor = {
  [HistoryItemStatus.paid]: "#DFEEDD",
  [HistoryItemStatus.failed]: "#F6D5DA",
  [HistoryItemStatus.draft]: "#E6E6E6",
  [HistoryItemStatus.open]: "#E6E6E6",
};

const getTextColor = {
  [HistoryItemStatus.paid]: "#53954A",
  [HistoryItemStatus.failed]: "#DC6675",
  [HistoryItemStatus.draft]: "#4D4D4D",
  [HistoryItemStatus.open]: "#4D4D4D",
};

interface Props {
  status: HistoryItemStatus;
}

const HistoryStatusChip = ({ status }: Props) => {
  return (
    <StyledChip
      color="custom"
      hexColor={getBgHexColor[status]}
      label={getLabel[status]}
      textColor={getTextColor[status]}
    />
  );
};

const StyledChip = styled(Chip)<{ textColor: string }>(({ textColor, theme }) => ({
  borderRadius: "51px",
  padding: "2px 12px",
  height: "auto",
  cursor: "default",

  "& .MuiChip-label": {
    ...theme.typography.article,
    fontSize: "10px",
    color: textColor,
  },
}));

export default HistoryStatusChip;
