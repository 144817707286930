import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Theme, styled, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@maestro-org/ui-kit";

import { Network, Project } from "../../../types/project";
import { EntryType } from "../../../types/table";

import {
  getAllprojects,
  getIsProjectCreating,
  getLoadingProjectsIds,
  getProjectsLoading,
} from "../../../redux/reducers/projectsReducer";
import { updatePopup } from "../../../redux/actions/popupsActions";

import Container from "../../../components/Container/Container";
import Table from "../../../components/Table/Table";
import ApiKeyTableCell from "./ApiKeyTableCell";
import TableHeader from "../../../components/Table/TableHeader";
import TablePagination from "../../../components/Table/TablePagination";
import {
  FilledArrowDown,
  FilledArrowUp,
  PreprodTokenIcon,
  SearchIcon,
  SortTableIcon,
  TokenIcon,
  TrashIcon,
} from "../../../components/Icons";

import { compare } from "../../../lib/sort";
import { timeSince } from "../../../lib/date";
import { Popups } from "../../../types/popups";
import { getSubscriptionPlan } from "../../../redux/reducers/authReducer";
import { getUserSubscriptions } from "../../../redux/reducers/usersReducer";

const getNetworkTitle = {
  [Network.Mainnet]: "Mainnet",
  [Network.Preprod]: "Preprod",
  [Network.Preview]: "Preview",
};

const getNetworkIcon = {
  [Network.Mainnet]: <TokenIcon />,
  [Network.Preprod]: <PreprodTokenIcon />,
  [Network.Preview]: <PreprodTokenIcon />,
};

enum SortState {
  increasing = "increasing",
  descending = "descending",
}

interface SortedBy {
  columnName: string;
  state: SortState;
}

const Projects = () => {
  const [sortedBy, setSortedBy] = useState<SortedBy | null>(null);

  const allProjects = useSelector(getAllprojects);
  const isLoading = useSelector(getProjectsLoading);
  const isProjectCreating = useSelector(getIsProjectCreating);
  const loadingProjectsIds = useSelector(getLoadingProjectsIds);
  const subscription = useSelector(getSubscriptionPlan);
  const allSubscription = useSelector(getUserSubscriptions);

  const theme = useTheme();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const getHeadingSortIcon = (key: string) => (
    <SortArrowWrapper>
      {sortedBy?.columnName !== key || !sortedBy ? (
        <SortTableIcon />
      ) : sortedBy.state === SortState.increasing ? (
        <FilledArrowUp />
      ) : (
        <FilledArrowDown />
      )}
    </SortArrowWrapper>
  );

  const heading: EntryType = {
    name: "Name",
    network: {
      title: "Network",
      adornemnt: getHeadingSortIcon("network"),
      adornmentPosition: "end",
    },
    // requests: {
    //   title: "Requests (24h)",
    //   adornemnt: getHeadingSortIcon("requests"),
    //   adornmentPosition: "end",
    // },
    age: {
      title: "Age",
      adornemnt: getHeadingSortIcon("age"),
      adornmentPosition: "end",
    },
    apiKey: "API key",
    delete: "",
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
    event.stopPropagation();
    removeProject(id);
  };

  const createRow = (project: Project): EntryType => ({
    id: project.id,
    name: project.name,
    network: {
      title: getNetworkTitle[project.network],
      adornemnt: getNetworkIcon[project.network],
      adornmentPosition: "start",
    },
    requests: project["24_hour_requests"],
    age: timeSince(new Date(project.created_at)),
    apiKey: (
      <ApiKeyTableCell
        projectName={project.name}
        projectId={project.id}
        copyKey={project.api_key}
        network={project.network}
      />
    ),
    delete: (
      <div className="delete_project" onClick={(event) => handleDeleteClick(event, project.id)}>
        <TrashIcon />
      </div>
    ),
  });

  const [projects, setProjects] = useState<EntryType[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<EntryType[]>([]);
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = React.useState(0);

  const handleNextPage = () => setPage(page + 1);

  const handlePrevPage = () => setPage(page - 1);

  const handlePageChenge = (newPage: number) => setPage(newPage);

  const removeProject = (id: string) => {
    dispatch(updatePopup({ popup: Popups.deleteProject, status: true, prefilled: { projectId: id } }));
  };

  const sortByKey = (key: string) => {
    if (!sortedBy || sortedBy.columnName !== key) {
      setSortedBy({ columnName: key, state: SortState.increasing });
      setFilteredProjects((projects) =>
        projects.sort((project1: any, project2: any) =>
          compare(project1[key]?.title || project1[key], project2[key]?.title || project2[key]),
        ),
      );
      return;
    }
    if (sortedBy.state === SortState.increasing && sortedBy.columnName === key) {
      setSortedBy({ columnName: key, state: SortState.descending });
      setFilteredProjects((projects) =>
        projects
          .sort((project1: any, project2: any) =>
            compare(project1[key]?.title || project1[key], project2[key]?.title || project2[key]),
          )
          .reverse(),
      );
      return;
    }
    if (sortedBy.state === SortState.descending) {
      setSortedBy(null);
      filterProjects(search);
      return;
    }
  };

  const sortTable = [
    { columnName: "network", handleClick: () => sortByKey("network") },
    { columnName: "requests", handleClick: () => sortByKey("requests") },
    { columnName: "age", handleClick: () => sortByKey("age") },
  ];

  const handleClick = () => {
    dispatch(
      updatePopup({
        popup: Popups.addNewProject,
        status: true,
      }),
    );
  };

  const rowsPerPage = 5;

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const filterProjects = (key: string) => {
    setFilteredProjects(
      projects.filter((project) => (project.name as string).toLocaleLowerCase().includes(key.toLocaleLowerCase())),
    );
  };

  useEffect(() => {
    filterProjects(search);
  }, [search, projects]);

  useEffect(() => {
    if (!allProjects) return;
    setProjects(allProjects.map(createRow));
    setFilteredProjects(allProjects.map(createRow));
  }, [allProjects]);

  const currentSubscription = allSubscription.find((item) => subscription === item.subscription_name);

  return (
    <OuterWrapper empty={!projects.length}>
      <Container>
        <InnerWrapper>
          <TableHeader
            action={
              <SearchTextfield
                variant="outlined"
                value={search}
                onChange={handleSearchChange}
                startIcon={<SearchIcon />}
                placeholder="Search projects"
              />
            }
            triggerValue="New project"
            title="Projects"
            newEntry={{
              handleClick,
              disable: [
                {
                  condition:
                    allProjects.length >=
                    (currentSubscription && +currentSubscription?.project_limit
                      ? +currentSubscription?.project_limit
                      : 0),
                  message: "Project limit reached. To create more projects, upgrade to a higher subscription tier.",
                },
              ],
              isLoading: isProjectCreating,
            }}
          />
          <Table
            data={filteredProjects}
            heading={heading}
            page={page}
            isLoading={isLoading}
            rowsPerPage={rowsPerPage}
            rowSx={getRowStyle(theme)}
            empyStateMessage="No results. Start by adding your first project"
            sortTable={sortTable}
            loadingEntriesIds={loadingProjectsIds}
            handleRowClick={(id) => navigate(`/project/${id}`)}
          />
          <TablePagination
            page={page}
            rowsPerPage={rowsPerPage}
            entries={projects}
            handleNextPage={handleNextPage}
            handlePrevPage={handlePrevPage}
            handlePageChenge={handlePageChenge}
          />
        </InnerWrapper>
      </Container>
    </OuterWrapper>
  );
};

const OuterWrapper = styled("div")<{ empty: boolean }>(({ empty, theme }) => ({
  background: "#F5F5F5",
  boxShadow: "0px 8px 12px -10px rgba(15, 3, 17, 0.16)",
  padding: `0 0 ${empty ? "48px" : "64px"}`,

  [theme.breakpoints.down("lg")]: {
    padding: `0 0 ${empty ? "48px" : "56px"}`,
  },

  [theme.breakpoints.down("sm")]: {
    padding: 0,
  },
}));

const InnerWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "40px",

  [theme.breakpoints.down(709)]: {
    "& .MuiTable-root": {
      minWidth: "637px",
    },

    "& .MuiPaper-root": {
      overflow: "auto hidden !important",
    },

    "& .first-header-cell, & .first-body-cell": {
      position: "sticky",
      left: 0,
      background: theme.palette.common.white,
      boxShadow: "0px 0px 24px -6px rgba(0, 0, 0, 0.16)",
      marginRight: "38px",
    },
  },
}));

const getRowStyle = (theme: Theme) => ({
  "& .delete_project": {
    visibility: "hidden",
    opacity: "0",
    transition: "0.3s",
    cursor: "pointer",
  },
  "&:hover": {
    "& .delete_project": {
      visibility: "visible",
      opacity: "1",
    },
  },

  // [theme.breakpoints.down("lg")]: {
  //   "& .MuiTableCell-root": {
  //     padding: "16px 4px",
  //   },
  // },
});

const SearchTextfield = styled(TextField)(({ theme }) => ({
  width: "456px",

  "& .MuiOutlinedInput-root": {},

  "& > div > input": {
    padding: "12.5px 14px 12.5px 0",
  },
  "& > div > fieldset": {
    borderStyle: "none !important",
    background: "transparent !important",
  },

  [theme.breakpoints.down("lg")]: {
    width: "100%",
    maxWidth: "none",
  },

  [theme.breakpoints.down("sm")]: {
    "& .MuiInputBase-root": {
      padding: "0px 24px 0 0",
    },
  },
}));

const SortArrowWrapper = styled("div")(({ theme }) => ({
  "& path": {
    fill: theme.palette.grey[500],
  },
}));

export default Projects;
