import { styled, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Loader } from "@maestro-org/ui-kit";

import { getSubscriptionServicesLoading } from "../../../redux/reducers/usersReducer";

import PlanSettingsCard, { CardTypes } from "./PlanSettingsCard";
import { BlockchainIndexerIcon, TransactionMonitoringIcon, TurboTXIcon } from "../../../components/Icons";

import { SubscriptionService, UserSubscriptions } from "../../../types/user";

interface Props {
  activePlan?: UserSubscriptions;
  services?: SubscriptionService[];
}

const Addons = ({ activePlan, services }: Props) => {
  const isLoading = useSelector(getSubscriptionServicesLoading);

  return (
    <Wrapper>
      <Typography variant="h5" color="grey.A200">
        Package features
      </Typography>
      {isLoading ? (
        <Loader />
      ) : (
        <CardsWrapper>
          {!!services?.length &&
            services.map((service) => (
              <PlanSettingsCard
                type={CardTypes.Expand}
                key={service.service_name}
                disabled={
                  !activePlan || !service.subscription_plans.split("\\n").includes(activePlan.subscription_name)
                }
                availableIn={service.subscription_plans.split("\\n")}
                title={service.service_name}
                description={service.details.split("\\n")}
                icon={getIcon[service.service_name as keyof typeof getIcon]}
              />
            ))}
        </CardsWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "40px",
});

const CardsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  rowGap: "20px",

  "& > div": {
    width: "calc(50% - 18px)",
  },

  [theme.breakpoints.down("lg")]: {
    "& > div": {
      width: "100%",
    },
  },

  [theme.breakpoints.down(1101)]: {
    "& > div": {
      width: "calc(50% - 18px)",
    },
  },

  [theme.breakpoints.down("sm")]: {
    "& > div": {
      width: "100%",
    },
  },
}));

const getIcon = {
  ["Blockchain Indexer API"]: <BlockchainIndexerIcon />,
  ["Transaction Manager"]: <TransactionMonitoringIcon />,
  ["Turbo Transactions"]: <TurboTXIcon />,
};

export default Addons;
