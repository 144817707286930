import { toast } from "react-toastify";
import { takeEvery, put } from "redux-saga/effects";
import {
  DELETE_USER_SERVER,
  GET_BILLING_INVOICES_SERVER,
  GET_SUBSCRIPTION_SERVICES_SERVER,
  GET_USER_CREDIT_USAGE_SERVER,
  GET_USER_SERVER,
  GET_USER_SUBSCRIPTIONS_SERVER,
  UPDATE_BILLING_SERVER,
  changeBillingDataUpdated,
  changeIsBillingUpdating,
  upateUserMetricsLoading,
  updateCreditUsage,
  updateCreditUsageLoading,
  updateInvoices,
  updateInvoicesLoading,
  updateIsUserDeleting,
  updateSubscriptionServices,
  updateSubscriptionServicesLoading,
  updateUser,
  updateUserLoading,
  updateUserMetrics,
  updateUserSubscriptionLoading,
  updateUserSubscriptions,
} from "../actions/userActions";
import { clearAuthData, updateIsAuthorized } from "../actions/authActions";
import { GET_METRICS_OVERVIEW_SERVER } from "../actions/singleProjectActions";
import { updateActivePaymentMethod } from "../actions/stripeActions";

const usersSagas = [
  takeEvery(DELETE_USER_SERVER, handleDeleteUser),
  takeEvery(`${DELETE_USER_SERVER}_SUCCESS`, handleDeleteUserSuccess),
  takeEvery(`${DELETE_USER_SERVER}_FAIL`, handleDeleteUserFail),

  takeEvery(GET_USER_SERVER, handleGetUser),
  takeEvery(`${GET_USER_SERVER}_SUCCESS`, handleGetUserSuccess),
  takeEvery(`${GET_USER_SERVER}_FAIL`, handleGetUserFail),

  takeEvery(GET_METRICS_OVERVIEW_SERVER, handleGetUserMetrics),
  takeEvery(`${GET_METRICS_OVERVIEW_SERVER}_SUCCESS`, handleGetUserMetricsSuccess),
  takeEvery(`${GET_METRICS_OVERVIEW_SERVER}_FAIL`, handleGetUserMetricsFail),

  takeEvery(GET_USER_SUBSCRIPTIONS_SERVER, handleGetUserSubscription),
  takeEvery(`${GET_USER_SUBSCRIPTIONS_SERVER}_SUCCESS`, handleGetUserSubscriptionSuccess),
  takeEvery(`${GET_USER_SUBSCRIPTIONS_SERVER}_FAIL`, handleGetUserSubscriptionFail),

  takeEvery(GET_SUBSCRIPTION_SERVICES_SERVER, handleGetSubscriptionServices),
  takeEvery(`${GET_SUBSCRIPTION_SERVICES_SERVER}_SUCCESS`, handleGetSubscriptionServicesSuccess),
  takeEvery(`${GET_SUBSCRIPTION_SERVICES_SERVER}_FAIL`, handleGetSubscriptionServicesFail),

  takeEvery(GET_USER_CREDIT_USAGE_SERVER, handleGetCreditUsage),
  takeEvery(`${GET_USER_CREDIT_USAGE_SERVER}_SUCCESS`, handleGetCreditUsageSuccess),
  takeEvery(`${GET_USER_CREDIT_USAGE_SERVER}_FAIL`, handleGetCreditUsageFail),

  takeEvery(UPDATE_BILLING_SERVER, handleUpdateBilling),
  takeEvery(`${UPDATE_BILLING_SERVER}_SUCCESS`, handleUpdateBillingSuccess),
  takeEvery(`${UPDATE_BILLING_SERVER}_FAIL`, handleUpdateBillingFail),

  takeEvery(GET_BILLING_INVOICES_SERVER, handleGetInvoices),
  takeEvery(`${GET_BILLING_INVOICES_SERVER}_SUCCESS`, handleGetInvoicesSuccess),
  takeEvery(`${GET_BILLING_INVOICES_SERVER}_FAIL`, handleGetInvoicesFail),
];

function* handleDeleteUser() {
  yield put(updateIsUserDeleting({ status: true }));
}

function* handleDeleteUserFail() {
  yield put(updateIsUserDeleting({ status: false }));
  toast.error("Could not delete current account. Try again later!");
}

function* handleDeleteUserSuccess() {
  yield put(updateIsUserDeleting({ status: false }));
  yield put(updateIsAuthorized({ status: false }));
  yield put(clearAuthData());
  toast.success("Account was successfully deleted!");
}

function* handleGetUser() {
  yield put(updateUserLoading({ status: true }));
}

function* handleGetUserFail() {
  yield put(updateUserLoading({ status: false }));
  toast.error("Could not get user data. Try again later!");
}

function* handleGetUserSuccess(action: any) {
  yield put(updateUserLoading({ status: false }));
  yield put(updateUser({ user: action.payload.data }));
  yield put(updateActivePaymentMethod({ id: action.payload.data.default_payment_method_id }));
}

function* handleGetUserMetrics() {
  yield put(upateUserMetricsLoading({ status: true }));
}

function* handleGetUserMetricsFail() {
  yield put(upateUserMetricsLoading({ status: false }));
  toast.error("Could not get user metrics. Try again later!");
}

function* handleGetUserMetricsSuccess(action: any) {
  yield put(upateUserMetricsLoading({ status: false }));
  yield put(updateUserMetrics({ ...action.payload.data }));
}

function* handleGetUserSubscription() {
  yield put(updateUserSubscriptionLoading({ status: true }));
}

function* handleGetUserSubscriptionFail() {
  yield put(updateUserSubscriptionLoading({ status: false }));
  toast.error("Could not get user subscriptions. Try again later!");
}

function* handleGetUserSubscriptionSuccess(action: any) {
  yield put(updateUserSubscriptionLoading({ status: false }));
  yield put(updateUserSubscriptions({ data: action.payload.data }));
}

function* handleGetSubscriptionServices() {
  yield put(updateSubscriptionServicesLoading({ status: true }));
}

function* handleGetSubscriptionServicesFail() {
  yield put(updateSubscriptionServicesLoading({ status: false }));
  toast.error("Could not get subscription services data. Try again later!");
}

function* handleGetSubscriptionServicesSuccess(action: any) {
  yield put(updateSubscriptionServicesLoading({ status: false }));
  yield put(updateSubscriptionServices({ data: action.payload.data }));
}

function* handleGetCreditUsage() {
  yield put(updateCreditUsageLoading({ status: true }));
}

function* handleGetCreditUsageFail() {
  yield put(updateCreditUsageLoading({ status: false }));
  toast.error("Could not get credits usage. Try again later!");
}

function* handleGetCreditUsageSuccess(action: any) {
  yield put(updateCreditUsageLoading({ status: false }));
  yield put(updateCreditUsage({ data: action.payload.data }));
}

function* handleUpdateBilling() {
  yield put(changeIsBillingUpdating({ status: true }));
}

function* handleUpdateBillingFail() {
  yield put(changeIsBillingUpdating({ status: false }));
  toast.error("Could not update billing info!");
}

function* handleUpdateBillingSuccess() {
  yield put(changeIsBillingUpdating({ status: false }));
  yield put(changeBillingDataUpdated({ status: true }));
}

function* handleGetInvoices() {
  yield put(updateInvoicesLoading({ status: true }));
}

function* handleGetInvoicesFail() {
  yield put(updateInvoicesLoading({ status: false }));
  toast.error("Could not get invoice history!");
}

function* handleGetInvoicesSuccess(action: any) {
  yield put(updateInvoicesLoading({ status: false }));
  yield put(updateInvoices({ invoices: action.payload.data.invoices }));
}

export default usersSagas;
