import { toast } from "react-toastify";
import { takeEvery, put } from "redux-saga/effects";
import {
  ADD_PAYMENT_SERVER,
  DELETE_PAYMENT_SERVER,
  GET_BILLING_DATA_SERVER,
  GET_CUSTOMER_SERVER,
  SET_PAYMENT_SERVER,
  UPDATE_USER_FIRST_SUBSCRIPTION_SERVER,
  UPDATE_USER_SUBSCRIPTION_SERVER,
  chageSubscriptionUpdated,
  getBillingDataServer,
  setPaymentServer,
  updateActivePaymentMethod,
  updateBillingData,
  updateBillingDataLoading,
  updateClientSecret,
  updateCustomer,
  updateCustomerLoading,
  updateLoadingMethods,
} from "../actions/stripeActions";
import { getUserServer } from "../actions/userActions";

const stripeSagas = [
  takeEvery(`${GET_BILLING_DATA_SERVER}`, handleGetBillingData),
  takeEvery(`${GET_BILLING_DATA_SERVER}_SUCCESS`, handleGetBillingDataSuccess),
  takeEvery(`${GET_BILLING_DATA_SERVER}_FAIL`, handleGetBillingDataFail),

  takeEvery(`${ADD_PAYMENT_SERVER}_SUCCESS`, handleAddPaymentSuccess),
  takeEvery(`${ADD_PAYMENT_SERVER}_FAIL`, handleAddPaymentFail),

  takeEvery(`${DELETE_PAYMENT_SERVER}`, handleDeleteMethod),
  takeEvery(`${DELETE_PAYMENT_SERVER}_SUCCESS`, handleDeleteMethodSuccess),
  takeEvery(`${DELETE_PAYMENT_SERVER}_FAIL`, handleDeleteMethodFail),

  takeEvery(`${SET_PAYMENT_SERVER}_SUCCESS`, handleSetPaymentSuccess),
  takeEvery(`${SET_PAYMENT_SERVER}_FAIL`, handleSetPaymentFail),

  takeEvery(`${UPDATE_USER_FIRST_SUBSCRIPTION_SERVER}_SUCCESS`, handleUpdateSubscriptionSuccess),
  takeEvery(`${UPDATE_USER_FIRST_SUBSCRIPTION_SERVER}_FAIL`, handleUpdateSubscriptionFail),

  takeEvery(`${UPDATE_USER_SUBSCRIPTION_SERVER}_SUCCESS`, handleUpdateSubscriptionSuccess),
  takeEvery(`${UPDATE_USER_SUBSCRIPTION_SERVER}_FAIL`, handleUpdateSubscriptionFail),

  takeEvery(`${GET_CUSTOMER_SERVER}`, handleGetCustomer),
  takeEvery(`${GET_CUSTOMER_SERVER}_SUCCESS`, handleGetCustomerSuccess),
  takeEvery(`${GET_CUSTOMER_SERVER}_FAIL`, handleGetCustomerFail),
];

function* handleGetBillingData() {
  yield put(updateBillingDataLoading({ status: true }));
}

function* handleGetBillingDataSuccess(action: any) {
  yield put(updateBillingDataLoading({ status: false }));
  const allMethods = action.payload.data.payment_methods;

  if (!allMethods) {
    yield put(updateBillingData({ paymentMethods: [] }));
    return;
  }

  const data = allMethods.map((item: any) => {
    const { billing_details, card, id } = item;
    let firstName,
      lastName = "";
    if (billing_details.name) [firstName, lastName] = billing_details.name.split(" ");

    const newBillingData = {
      address: billing_details.address,
      email: billing_details.email,
      firstName,
      lastName,
      phone: billing_details.phone,
    };

    const newCardData = {
      brand: card.brand,
      country: card.country,
      expMonth: card.exp_month,
      expYear: card.exp_year,
      last4: card.last4,
    };

    return { billingInfo: newBillingData, card: newCardData, id };
  });

  yield put(updateBillingData({ paymentMethods: data }));
  const active = action.meta.previousAction.payload.activeId;
}

function* handleGetBillingDataFail(action: any) {
  yield put(updateBillingDataLoading({ status: false }));
}

function* handleAddPaymentFail() {
  yield toast.error("Could not get client secret for adding payment method!");
}

function* handleAddPaymentSuccess(action: any) {
  yield put(updateClientSecret({ clientSecret: action.payload.data.client_secret }));
}

function* handleDeleteMethod(action: any) {
  yield put(updateLoadingMethods({ methodsIds: [...action.payload.loadingMethods, action.payload.methodId] }));
}

function* handleDeleteMethodFail(action: any) {
  const { loadingMethods, methodId } = action.meta.previousAction.payload;
  yield put(
    updateLoadingMethods({
      methodsIds: loadingMethods.filter((item: string) => item !== methodId),
    }),
  );
  toast.error("Could not delete payment method!");
}

function* handleDeleteMethodSuccess(action: any) {
  const { loadingMethods, methodId } = action.meta.previousAction.payload;
  yield put(
    updateLoadingMethods({
      methodsIds: loadingMethods.filter((item: string) => item !== methodId),
    }),
  );
  yield put(getBillingDataServer());
  toast.success("The payment method was successfully deleted!");
}

function* handleSetPaymentFail() {
  yield toast.error("Could not change payment method!");
}

function* handleSetPaymentSuccess(action: any) {
  // yield put(getUserServer());
  yield put(updateActivePaymentMethod({ id: action.payload.data.default_payment_method_id }));
  toast.success("Payment method was successfully changed!");
}

function* handleUpdateSubscriptionSuccess(action: any) {
  yield put(chageSubscriptionUpdated({ isUpdated: true, success: true }));
  yield put(getUserServer());
  toast.success("The subscription was successfully changed!");
}

function* handleUpdateSubscriptionFail(action: any) {
  const error = action.error.response.data.error;
  if (error === "User has too many projects to downgrade." || error === "User has too many webhooks to downgrade.") {
    toast.error(error);
  } else toast.error("Could not change subscription! Try again later!");
  yield put(chageSubscriptionUpdated({ isUpdated: true, success: false }));
}

function* handleGetCustomer() {
  yield put(updateCustomerLoading({ status: true }));
}

function* handleGetCustomerFail() {
  yield put(updateCustomerLoading({ status: false }));
  toast.error("Could not get customer info!");
}

function* handleGetCustomerSuccess(action: any) {
  yield put(updateCustomerLoading({ status: false }));
  yield put(updateCustomer({ customer: action.payload.data.customer }));
}

export default stripeSagas;
