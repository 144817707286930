import { Button, IconButton, TextField, TokenIcon } from "@maestro-org/ui-kit";
import { styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getAllprojects } from "../../../redux/reducers/projectsReducer";

import Container from "../../../components/Container/Container";
import { EditIcon, PreprodTokenIcon, UrlIcon } from "../../../components/Icons";

import { Network, networkLabels, Project as ProjectType } from "../../../types/project";

const getNetworkIcon = {
  [Network.Mainnet]: <TokenIcon />,
  [Network.Preprod]: <PreprodTokenIcon />,
  [Network.Preview]: <PreprodTokenIcon />,
};

const ProjectHeader = () => {
  const { id } = useParams();

  const allProjects = useSelector(getAllprojects);

  const [currentProject, setCurrentProject] = useState<ProjectType>({} as ProjectType);
  const [nameInputValue, setNameInputValue] = useState<string>("");
  const [editMode, setEditMode] = useState<boolean>(false);

  const toggleEditMode = () => {
    if (!editMode) {
      setNameInputValue(currentProject?.name || "");
    }
    setEditMode(!editMode);
  };

  const handleSubmitName = (event: React.KeyboardEvent<HTMLImageElement>) => {
    if (event.key === "Enter") {
      setCurrentProject({ ...currentProject, name: nameInputValue });
      toggleEditMode();
    }
  };

  const handleNameInputBlur = () => {
    console.log(nameInputValue);
    setCurrentProject({ ...currentProject, name: nameInputValue });
    toggleEditMode();
  };

  const handleNameInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameInputValue(event.target.value);
  };

  useEffect(() => {
    if (!id || !allProjects.length) return;
    const current = allProjects.find((item) => item.id === id);
    if (!current) return;
    setCurrentProject(current);
  }, [id, allProjects]);

  return (
    <Container>
      <Wrapper>
        <Top>
          <ProjectWrapper>
            <Row>
              {editMode ? (
                <InputWrapper>
                  <ProjectInput
                    inputRef={(input) => input && input.focus()}
                    variant="outlined"
                    value={nameInputValue}
                    onChange={handleNameInputChange}
                    onKeyDown={handleSubmitName}
                    onBlur={handleNameInputBlur}
                  />
                </InputWrapper>
              ) : (
                <Typography color="grey.A200" variant="h5">
                  <Project>{currentProject.name}</Project>
                </Typography>
              )}
            </Row>
            {/* {!editMode && (
              <StyledIconButton onClick={toggleEditMode}>
                <EditIcon />
              </StyledIconButton>
            )} */}
            <MainnetButton size="xsmall" startIcon={getNetworkIcon[currentProject.network]}>
              {networkLabels[currentProject.network]}
            </MainnetButton>
          </ProjectWrapper>
          {/* <Actions>
            <ActionsButton endIcon={<UrlIcon />}>URL</ActionsButton>
          </Actions> */}
        </Top>
      </Wrapper>
    </Container>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "4px",
});

const Top = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const ProjectWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  columnGap: "8px",

  [theme.breakpoints.down("md")]: {
    columnGap: "16px",
  },
}));

const Project = styled("span")({
  fontWeight: 600,
});

const InputWrapper = styled("div")({
  "& > div": {
    rowGap: 0,
  },
});

const ProjectInput = styled(TextField)({
  "& .MuiInputBase-root": {
    padding: "10px 10px",
  },
});

const Row = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "10px",
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: "24px",
  height: "24px",
  background: "#D9D9D9",

  "&:hover": {
    background: "#D9D9D9",
  },
}));

const Actions = styled("div")({
  display: "flex",
  alignItems: "center",
});

const ActionsButton = styled(Button)(({ theme }) => ({
  padding: "8px 12px",
  borderRadius: theme.borderRadius.xs,
  background: "transparent",
  color: theme.palette.primary.main,

  "& svg": {
    fill: "transparent",
  },
  "& path": {
    stroke: theme.palette.primary.main,
  },

  "&:hover": {
    background: "transparent",
    color: theme.palette.primary.main,
    "& svg": {
      fill: "transparent",
    },
  },
}));

const MainnetButton = styled(Button)(({ theme }) => ({
  padding: "3px 12px",
  borderRadius: "8px",
  // marginLeft: "80px",

  background: theme.palette.grey[100],
  color: theme.palette.grey.A200,

  "&:hover": {
    background: theme.palette.grey[100],
    color: theme.palette.grey.A200,
  },

  "& path": {
    fill: theme.palette.grey.A200,
  },
}));

export default ProjectHeader;
