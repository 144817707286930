import { styled, MenuItem as MuiMenuItem, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import { SettingsPages as SettingsPagesType } from "../../../types/settings";

import Logo, { LogoStyles } from "../../../components/Logo/Logo";
import { BackIcon, SettingsIcon } from "../../../components/Icons";

import { pages } from "../../../lib/routeUtils";

interface Page {
  value: SettingsPagesType;
  title?: string;
  href: string;
}

interface SidebatItem {
  title: string;
  pages: Page[] | Page;
}

interface Props {
  items: SidebatItem[];
  active: SettingsPagesType;
}

const Line = () => (
  <StyledLineWrapper>
    <StyledLine width="322" height="1200" viewBox="0 0 322 1200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M549.76 866.179C463.05 917.559 374.52 970.009 282.6 1021.43C266.51 1030.43 262.27 1051.62 273.61 1066.16C299.22 1098.96 325.64 1130.54 352.88 1160.87C667.5 1511.14 1021.54 1619.25 1263.14 1648.26L1262.43 1654.22C1110.86 1636.02 963.14 1592.76 823.4 1525.64C647.26 1441.04 487.46 1319.66 348.43 1164.87C321.11 1134.45 294.48 1102.59 268.61 1069.4C256.02 1053.24 233.56 1048.61 215.55 1058.37C179.74 1077.79 143.39 1096.99 106.39 1115.84C-71.16 1206.3 -239.42 1276.13 -408.02 1329.32C-612.09 1393.7 -818.48 1434.91 -1038.99 1455.3C-1159.14 1466.41 -1284.77 1471.18 -1412.4 1469.47C-1546.88 1467.67 -1688.05 1458.62 -1832 1442.57L-1831.33 1436.61C-1664.71 1455.19 -1509.15 1463.69 -1362.97 1463.69C-670.51 1463.69 -192.47 1272.41 211.68 1053.56C228.37 1044.52 233.4 1022.88 222.34 1007.45C189.95 962.229 158.94 914.879 129.51 865.639C58.37 746.639 -5.38 613.629 -59.98 470.299C-118.54 316.569 -168.1 146.959 -207.29 -33.8313C-249.04 -226.491 -280.13 -436.981 -299.67 -659.471L-293.69 -660.001C-231.23 50.9287 -55.9202 609.899 228.15 1005.42C239.68 1021.48 261.48 1026.29 278.74 1016.65C371.05 965.079 459.79 912.499 546.71 860.999C718.35 759.299 880.47 663.229 1051.63 584.609C1143.42 542.439 1229.77 508.999 1315.59 482.369C1411.37 452.649 1504.56 431.939 1600.5 419.059L1601.3 425.009C1204.37 478.289 886.41 666.689 549.77 866.159L549.76 866.179Z"
        fill="#C53DD8"
      />
    </StyledLine>
  </StyledLineWrapper>
);

const SettingsSidebar = ({ items, active }: Props) => {
  const activeCategory = items.find((item) =>
    !Array.isArray(item.pages) ? item.pages.value === active : item.pages.find((page) => page.value === active),
  );

  return (
    <Wrapper>
      <Logo style={LogoStyles.Dark} />
      <Link to={pages.main()}>
        <BackToDashboardWrapper>
          <BackIcon />
          <Typography color="grey.A200" variant="paragraphMedium">
            Back to Dashboard
          </Typography>
        </BackToDashboardWrapper>
      </Link>
      <SettingsPages>
        <SettingsLabelWrapper>
          <SettingsIcon />
          <Typography color="grey.400" variant="paragraphMedium">
            SETTINGS
          </Typography>
        </SettingsLabelWrapper>
        {items.map((item) => (
          <StyledLink key={item.title} to={Array.isArray(item.pages) ? item.pages[0].href : item.pages.href}>
            <SettingsCategory active={item.title === activeCategory?.title}>
              <>
                <CategoryNameWrapper>
                  <Typography
                    color={!Array.isArray(item.pages) && item.pages.value === active ? "primary.main" : "grey.A200"}
                    variant="paragraphMedium"
                  >
                    {item.title}
                  </Typography>
                </CategoryNameWrapper>
                {Array.isArray(item.pages) &&
                  item.title === activeCategory?.title &&
                  item.pages.map((page) => (
                    <StyledLink key={item.title} to={page.href}>
                      <SettingsPageItem key={page.title}>
                        <Typography
                          color={page.value === active ? "primary.main" : "grey.400"}
                          variant="paragraphMedium"
                        >
                          {page.title}
                        </Typography>
                      </SettingsPageItem>
                    </StyledLink>
                  ))}
              </>
            </SettingsCategory>
          </StyledLink>
        ))}
      </SettingsPages>
      <Line />
    </Wrapper>
  );
};

const Wrapper = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  padding: "20px 0 0 64px",
  minWidth: "322px",
  background: theme.palette.grey[50],
  boxShadow: "3px 4px 16px -2px rgba(0, 0, 0, 0.16)",
  flexShrink: 0,

  [theme.breakpoints.down("lg")]: {
    minWidth: "250px",
    padding: "20px 0 0 34px",
  },

  [theme.breakpoints.down(1101)]: {
    display: "none",
  },
}));

const BackToDashboardWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "8px",
  margin: "80px 0 56px",
  cursor: "pointer",
});

const SettingsLabelWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "12px",
  paddingBottom: "10px",
});

const SettingsPages = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const StyledLink = styled(Link)({
  textDecoration: "none",
  width: "100%",
});

const SettingsCategory = styled("div")<{ active: boolean }>(({ theme, active }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  position: "relative",

  "&:after": {
    transition: "0.3s",
    content: '""',
    position: "absolute",
    right: 0,
    top: "10px",
    bottom: 0,
    background: theme.palette.primary.main,
    width: "3px",
    opacity: active ? 1 : 0,
  },
}));

const CategoryNameWrapper = styled("div")({
  padding: "10px 0",
});

const SettingsPageItem = styled(MuiMenuItem)(({ theme }) => ({
  padding: "10px 0 10px 40px",
  width: "100%",
  borderRadius: theme.borderRadius.xs,
}));

const StyledLineWrapper = styled("div")(({ theme }) => ({
  pointerEvents: "none",
  position: "absolute",
  height: "100%",
  overflow: "hidden",
  top: "0px",
  right: "0px",
  width: "100%",
}));

const StyledLine = styled("svg")(({ theme }) => ({
  position: "absolute",
  zIndex: "200",
  left: "0px",
  top: "-150px",
  marginRight: "calc(-1 * (100vw - 100%))",

  [theme.breakpoints.down("lg")]: {
    left: "-50px",
  },
}));

export default SettingsSidebar;
