import React, { useEffect, useState } from "react";
import { IconButton } from "@maestro-org/ui-kit";
import {
  MenuItem as MuiMenuItem,
  styled,
  Typography,
  ListItemButton as MuiListItemButton,
  ListItem,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { getAuthData, getIsAuthorized, getSubscriptionPlan } from "../../redux/reducers/authReducer";
import { clearAuthData, updateIsAuthorized } from "../../redux/actions/authActions";

import Divider from "../Divider/Divider";
import Drawer from "../Drawer/Drawer";
import Menu from "../Menu/Menu";
import Breadcrumps from "../Breadcrumps/Breadcrumps";
import { DropdownIcon, LongArrowLeft, NavBarMenuIcon } from "../Icons";
import Logo, { LogoStyles } from "../Logo/Logo";

import { SubscriptionPlan } from "../../types/subscription";
import { PathElement } from "../../types/breadcrumpsPathTypes";
import { pages } from "../../lib/routeUtils";

const getPlanLabel = {
  [SubscriptionPlan.artist]: "Artist",
  [SubscriptionPlan.composer]: "Composer",
  [SubscriptionPlan.conductor]: "Conductor",
  [SubscriptionPlan.virtuoso]: "Virtuoso",
};

interface Props {
  actions?: React.ReactNode;
  path: PathElement[];
}

const SettingsNavigation = ({ actions, path }: Props) => {
  const isAuthorized = useSelector(getIsAuthorized);
  const userData = useSelector(getAuthData);
  const dispatch = useDispatch();
  const subscriptionPlan = useSelector(getSubscriptionPlan);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [mobileSpacing, changeMobileSpacing] = useState(24);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(open);
  };

  const handleLogOut = () => {
    if (!isAuthorized) return;

    dispatch(updateIsAuthorized({ status: false }));
    dispatch(clearAuthData());
    toast.success("Successfully logged out!");
  };

  useEffect(() => {
    const wrapper: any = document.querySelector("#navigation");

    window.addEventListener("scroll", () => {
      const elemRect = wrapper.getBoundingClientRect();
      const elemViewportOffset = elemRect.top;
      changeMobileSpacing(elemViewportOffset);
    });
  }, []);

  return (
    <Wrapper id="navigation">
      <BreadcrumpsWrapper>
        <Breadcrumps path={path} />
      </BreadcrumpsWrapper>
      <LogoWrapper>
        <Logo style={LogoStyles.Dark} />
      </LogoWrapper>
      <Actions>
        <DocumentationWrapper>{actions}</DocumentationWrapper>
        {userData && (
          <Menu
            email={userData?.email || userData.nickname}
            endIcon={
              <MenuIconWrapper>
                <DropdownIcon />
              </MenuIconWrapper>
            }
          >
            <MenuTopSection>
              <UserInfoWrapper>
                <Typography color="textColor.dark" variant="paragraphMedium">
                  {`${userData.firstName} ${userData?.lastName || ""}`}
                </Typography>
                <Typography color="menu.grayText" variant="article">
                  {userData.email || userData.nickname}
                </Typography>
              </UserInfoWrapper>
              <Ref to={pages.subscriptionPlan()}>
                <Typography color="menu.refColor" variant="article">
                  {getPlanLabel[subscriptionPlan]}
                </Typography>
              </Ref>
            </MenuTopSection>
            <DividerWrapper>
              <Divider />
            </DividerWrapper>
            <Link to={pages.settings()}>
              <MenuItem>
                <Typography color="textColor.dark" variant="paragraphMedium">
                  Settings
                </Typography>
              </MenuItem>
            </Link>
            <MenuItem onClick={handleLogOut}>
              <Typography color="textColor.dark" variant="paragraphMedium">
                Logout
              </Typography>
            </MenuItem>
          </Menu>
        )}
        <DrawerMenu>
          <Drawer
            open={openDrawer}
            toggleDrawer={toggleDrawer}
            openTrigger={
              <StyledIconButton variant="light" onClick={toggleDrawer(true)}>
                <NavBarMenuIcon />
              </StyledIconButton>
            }
            mobileSpacing={mobileSpacing}
          >
            <DrawerItems />
          </Drawer>
        </DrawerMenu>
      </Actions>
    </Wrapper>
  );
};

const Wrapper = styled("div")(({ theme }) => ({
  background: theme.palette.grey["50"],
  borderBottom: `1px solid ${theme.palette.grey["100"]}`,
  display: "flex",
  padding: "20px 64px 20px 284px",
  alignItems: "center",
  justifyContent: "space-between",

  [theme.breakpoints.down("xl")]: {
    paddingLeft: "150px",
  },

  [theme.breakpoints.down("lg")]: {
    paddingLeft: "100px",
  },

  [theme.breakpoints.down(1101)]: {
    padding: "20px 24px",
  },

  [theme.breakpoints.down("sm")]: {
    padding: "18px 20px",
  },
}));

const BreadcrumpsWrapper = styled("div")(({ theme }) => ({
  [theme.breakpoints.down(1101)]: {
    display: "none",
  },
}));

const LogoWrapper = styled("div")(({ theme }) => ({
  [theme.breakpoints.up(1101)]: {
    display: "none",
  },
}));

const Actions = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  columnGap: "40px",
  width: "370px",
  justifyContent: "flex-end",

  [theme.breakpoints.down(1101)]: {
    columnGap: "24px",
  },

  [theme.breakpoints.down("sm")]: {
    columnGap: "20px",
  },
}));

const DocumentationWrapper = styled("div")(({ theme }) => ({
  [theme.breakpoints.down(1101)]: {
    display: "none",
  },
}));

const MenuIconWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  [theme.breakpoints.down(1101)]: {
    display: "none",
  },
}));

const DrawerMenu = styled("div")(({ theme }) => ({
  [theme.breakpoints.up(1101)]: {
    display: "none",
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: "38px",
  height: "38px",
  background: "transparent",

  "&:hover": {
    background: "transparent",
  },
}));

const MenuItem = styled(MuiMenuItem)({
  padding: "8px 24px 8px 12px",
});

const MenuTopSection = styled("div")({
  padding: "8px 24px 8px 12px",
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
});

const UserInfoWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const Ref = styled(Link)(({ theme }) => ({
  textDecoration: "underline",
  color: theme.palette.primary.main,
}));

const DividerWrapper = styled("div")({
  padding: "16px 12px",
});

export enum NavItems {
  dashboard = "Dashboard",
  transactions = "Transactions",
  events = "Events",
  nodes = "Nodes",
}

interface INavItem {
  name: NavItems;
  link: string;
}

const navItems: INavItem[] = [
  {
    name: NavItems.dashboard,
    link: pages.main(),
  },
  {
    name: NavItems.transactions,
    link: pages.transactions(),
  },
  {
    name: NavItems.events,
    link: "/",
  },
  {
    name: NavItems.nodes,
    link: "/",
  },
];

interface DrawerItem {
  name: string;
  link: string;
  subcategories?: DrawerItem[];
}

const drawerNavItems: DrawerItem[] = [
  {
    name: "Account",
    link: pages.account(),
    subcategories: [
      {
        name: "Account details",
        link: pages.account(),
      },
      // {
      //   name: "Credentials",
      //   link: pages.credentials(),
      // },
    ],
  },
  {
    name: "Subscription",
    link: pages.subscriptionPlan(),
    subcategories: [
      {
        name: "Overview",
        link: pages.subscriptionPlan(),
      },
      {
        name: "Plans",
        link: pages.settings(),
      },
    ],
  },
  {
    name: "Invoices",
    link: pages.billing(),
  },
];

const DrawerItems = () => {
  const location = useLocation();

  // console.log(location.pathname === ref.link);

  const isCategoryOpen = (categoryName: string) => {
    const currentCategory = drawerNavItems.find(
      (category) => !!category?.subcategories?.find((item) => item.link === location.pathname),
    );
    return currentCategory?.name === categoryName;
  };

  return (
    <DrawerContent>
      <Link to={pages.main()}>
        <BackToDashboard>
          <LongArrowLeft />
          <BackToDashboardText color="grey.A200" variant="h5">
            Back to Dashboard
          </BackToDashboardText>
        </BackToDashboard>
      </Link>
      <DrawerItemsWrapper>
        {drawerNavItems.map((ref) => (
          <>
            <Link to={ref.link} key={ref.name}>
              <NavbarRef
                color="grey.A200"
                variant="h5"
                active={ref.link === location.pathname && !ref.subcategories?.length}
              >
                <ListItem>
                  <ListItemButton>{ref.name}</ListItemButton>
                </ListItem>
              </NavbarRef>
            </Link>
            {!!ref.subcategories?.length && isCategoryOpen(ref.name) && (
              <SubcategoriesWrapper>
                {ref.subcategories.map((item) => (
                  <Link to={item.link} key={item.name}>
                    <NavbarRef color="grey.400" variant="h5" active={item.link === location.pathname}>
                      <ListItem>
                        <SubListItemButton>{item.name}</SubListItemButton>
                      </ListItem>
                    </NavbarRef>
                  </Link>
                ))}
              </SubcategoriesWrapper>
            )}
          </>
        ))}
      </DrawerItemsWrapper>
    </DrawerContent>
  );
};

const DrawerContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "24px",
});

const BackToDashboard = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "16px",
  cursor: "pointer",
});

const BackToDashboardText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    ...theme.typography.h6,
    color: theme.palette.grey.A200,
  },
}));

const DrawerItemsWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  paddingTop: "244px",
  margin: "0 auto",
});

const ListItemButton = styled(MuiListItemButton)(({ theme }) => ({
  padding: "4px 10px",
  borderRadius: theme.borderRadius.sm,
}));

const NavbarRef = styled(Typography)<{ active?: boolean }>(({ active, theme }) => ({
  cursor: "pointer",

  color: active ? theme.palette.primary.main : "none",
}));

const SubcategoriesWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  paddingLeft: "40px",
});

const SubListItemButton = styled(MuiListItemButton)(({ theme }) => ({
  padding: "2px 10px",
  borderRadius: theme.borderRadius.sm,
}));

export default SettingsNavigation;
