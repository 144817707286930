import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { styled, Typography, FormControlLabel as MuiFormControlLabel, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Auth0Error } from "auth0-js";

import { initialValuesRegister, registerFields } from "../../forms/register/form";
import { RegisterFormValues } from "../../forms/register/types";
import { registerSchema } from "../../forms/register/validation";

import { updateIsLoadingAuth } from "../../redux/actions/authActions";
import { getAuthLoading } from "../../redux/reducers/authReducer";
import { createUserServer } from "../../redux/actions/userActions";

import AuthLayout from "../../layouts/AuthLayout";

import { Button, Checkbox } from "@maestro-org/ui-kit";
import Divider from "../../components/Divider/Divider";
import TextField from "./components/TextField";
import { GithubIcon, GoogleIcon } from "../../components/Icons";

import { auth } from "../../services/auth0.service";
import { AUTH0_LOGIN_REDIRECT_URI } from "../../config";
import withAuthRedirect from "../../hooks/withAuthRedirect";

const SignUpPage = () => {
  const [agreed, setAgreed] = useState(false);

  const handleAgreementToggle = () => setAgreed(!agreed);

  const isLoading = useSelector(getAuthLoading);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const onSubmit = (values: RegisterFormValues) => {
    dispatch(updateIsLoadingAuth({ status: true }));
    auth.signup(
      {
        email: values.email,
        password: values.password,
        connection: `${process.env.REACT_APP_AUTH0_REALM}`,
        userMetadata: { firstName: values.firstName, lastName: values.lastName },
      },
      function (error: Auth0Error | null, result: any) {
        dispatch(updateIsLoadingAuth({ status: false }));
        if (error) {
          console.log("SINGUP ERROR", error);
          if (error.statusCode === 400)
            toast.error("Failed! Make sure you have not registered with this email before or try again later");
          return;
        }
        toast.success("You created an account successfully");
        navigate("/login");
        console.log("SIGNUP SUCCESS", result);
      },
    );
    // dispatch(registerAccountServer(values));
  };

  const formik = useFormik({
    initialValues: initialValuesRegister,
    validationSchema: registerSchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit,
  });

  const handleSignUpWithGoogle = () => {
    auth.authorize({
      domain: `${process.env.REACT_APP_AUTH0_DOMAIN}`,
      connection: "google-oauth2",
      responseType: process.env.REACT_APP_AUTH0_LOGIN_RESPONSE_TYPE,
      mode: "signUp",
      redirectUri: AUTH0_LOGIN_REDIRECT_URI,
    });
  };

  const handleSignUpWithGithub = () => {
    auth.authorize({
      domain: `${process.env.REACT_APP_AUTH0_DOMAIN}`,
      connection: "github",
      responseType: process.env.REACT_APP_AUTH0_LOGIN_RESPONSE_TYPE,
      mode: "signUp",
      redirectUri: AUTH0_LOGIN_REDIRECT_URI,
    });
  };

  useEffect(() => {
    dispatch(updateIsLoadingAuth({ status: false }));
  }, []);

  return (
    <AuthLayout title="The developer platform for Cardano">
      <form onSubmit={formik.handleSubmit}>
        <Wrapper>
          <Top>
            <StyledTitle color="textColor.dark">Join Maestro</StyledTitle>
            <Typography color="textColor.dark" variant="paragraphMedium">
              Already have an account? <Ref to="/login">Log In</Ref>
            </Typography>
          </Top>
          <FieldsWrapper>
            {registerFields.map((field) => (
              <TextField key={field.name} field={field} {...formik} />
            ))}
          </FieldsWrapper>
          <FormControlLabel
            control={<Checkbox variant="outlined" value={agreed} onChange={handleAgreementToggle} />}
            label={
              <AgreePolicyText>
                I agree and accept Maestro&apos;s{" "}
                <ExternalRef
                  href="https://storage.googleapis.com/ispo-marketplace/legal/Terms_of_Use.pdf"
                  target="_blank"
                >
                  Terms of Use
                </ExternalRef>{" "}
                and{" "}
                <ExternalRef
                  href="https://storage.googleapis.com/ispo-marketplace/legal/Privacy_Policy.pdf"
                  target="_blank"
                >
                  Privacy policy
                </ExternalRef>
              </AgreePolicyText>
            }
          />
          <Button size="medium" type="submit" onMouseDown={(e) => e.preventDefault()} disabled={isLoading || !agreed}>
            {isLoading ? <CircularProgress /> : "Sign Up"}
          </Button>
          <DividerWrapper>
            <Divider color="light" />
            <DividerText color="textColor.dark" variant="article">
              OR
            </DividerText>
          </DividerWrapper>
          <Actions>
            <GoogleButton onClick={handleSignUpWithGoogle} size="medium" startIcon={<GoogleIcon />}>
              Join with Google
            </GoogleButton>
            <Button onClick={handleSignUpWithGithub} size="medium" variant="secondary" startIcon={<GithubIcon />}>
              Join with GitHub
            </Button>
          </Actions>
        </Wrapper>
      </form>
    </AuthLayout>
  );
};

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 300,
  fontSize: "40px",
  lineHeight: "56px",
}));

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "40px",
});

const Top = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
});

const Ref = styled(Link)<any>(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: "pointer",
  textDecoration: "underline",
}));

const ExternalRef = styled("a")<any>(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: "pointer",
  textDecoration: "underline",
}));

const FieldsWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "16px",
});

const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  display: "flex",
  columnGap: "16px",
  margin: 0,
  userSelect: "none",
}));

const AgreePolicyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey["400"],
}));

const DividerWrapper = styled("div")({
  position: "relative",
});

const DividerText = styled(Typography)(({ theme }) => ({
  padding: "0 20px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.common.white,
}));

const Actions = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "20px",
});

const GoogleButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  borderColor: theme.palette.grey[50],
  color: theme.palette.grey["A200"],

  "&:hover": {
    backgroundColor: "#D6D6D6",
  },

  "&:disabled": {
    backgroundColor: theme.palette.grey[50],
  },
}));

export default withAuthRedirect(SignUpPage);
