import React from "react";
import { styled, Typography } from "@mui/material";

import { IconButton } from "@maestro-org/ui-kit";

interface Icon {
  src: string;
  element: React.ReactNode;
}

interface Props {
  icons: Icon[];
}

const Lower = ({ icons }: Props) => {
  const handlePrivacyPolicyClick = () => {
    window.open("https://storage.googleapis.com/ispo-marketplace/legal/Privacy_Policy.pdf", "_blank");
  };

  const handleTermsOfUseClick = () => {
    window.open("https://storage.googleapis.com/ispo-marketplace/legal/Terms_of_Use.pdf", "_blank");
  };

  return (
    <Wrapper>
      <Icons>
        {icons.map(({ src, element }) => (
          <StyledIconButton variant="light" key={src} href={src}>
            {element}
          </StyledIconButton>
        ))}
      </Icons>
      <CopyrightAndPrivacy>
        <CopyrightText color="textColor.dark" variant="paragraphSmall">
          © 2023 Maestro Blockchain Inc.
        </CopyrightText>
        <Row>
          <Ref onClick={handleTermsOfUseClick}>
            <UnderLinedText color="textColor.dark" variant="paragraphSmall">
              Terms Of Use
            </UnderLinedText>
          </Ref>
          <Ref onClick={handlePrivacyPolicyClick}>
            <UnderLinedText color="textColor.dark" variant="paragraphSmall">
              Privacy Policy
            </UnderLinedText>
          </Ref>
        </Row>
      </CopyrightAndPrivacy>
    </Wrapper>
  );
};

const UnderLinedText = styled(Typography)({
  textDecoration: "underline",
});

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "27px",

  [theme.breakpoints.down("xs")]: {
    rowGap: "24px",
  },
}));

const Icons = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  columnGap: "14px",

  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
  },
}));

const StyledIconButton = styled(IconButton)({
  background: "transparent",
  width: "38px",
  height: "38px",

  "&:hover": {
    background: "transparent",
  },
});

const CopyrightAndPrivacy = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  columnGap: "37px",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    rowGap: "10px",
  },
}));

const Row = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  columnGap: "37px",

  [theme.breakpoints.down("sm")]: {
    rowGap: "10px",
  },
}));

const CopyrightText = styled(Typography)(({ theme }) => ({
  paddingRight: "126px",

  [theme.breakpoints.down("xl")]: {
    paddingRight: "0",
  },
}));

const Ref = styled("div")({
  textDecoration: "none",
  cursor: "pointer",
});

export default Lower;
