import { toast } from "react-toastify";
import { takeEvery, put } from "redux-saga/effects";
import {
  LOGIN_SERVER,
  REGISTER_ACCOUNT_SERVER,
  RESET_PASSWORD_SERVER,
  setUpdatedUserPicture,
  updateUserData,
  updateIsLoadingAuth,
  updateIsUploadingImage,
  UPLOAD_IMAGE_SERVER,
} from "../actions/authActions";

const authSagas = [
  takeEvery(REGISTER_ACCOUNT_SERVER, handleRegister),
  takeEvery(`${REGISTER_ACCOUNT_SERVER}_SUCCESS`, handleRegisterSuccess),
  takeEvery(`${REGISTER_ACCOUNT_SERVER}_FAIL`, handleRegisterFail),

  takeEvery(LOGIN_SERVER, handleLogin),
  takeEvery(`${LOGIN_SERVER}_SUCCESS`, handleLoginSuccess),
  takeEvery(`${LOGIN_SERVER}_FAIL`, handleLoginFail),

  takeEvery(RESET_PASSWORD_SERVER, handleResetPassword),
  takeEvery(`${RESET_PASSWORD_SERVER}_SUCCESS`, handleResetPasswordSuccess),
  takeEvery(`${RESET_PASSWORD_SERVER}_FAIL`, handleResetPasswordFail),

  takeEvery(UPLOAD_IMAGE_SERVER, handleUploadImageServer),
  takeEvery(`${UPLOAD_IMAGE_SERVER}_SUCCESS`, handleUploadImageServerSuccess),
  takeEvery(`${UPLOAD_IMAGE_SERVER}_FAIL`, handleUploadImageServerFail),
];

function* handleRegister() {
  yield put(updateIsLoadingAuth({ status: true }));
}

function* handleRegisterFail() {
  yield put(updateIsLoadingAuth({ status: false }));
  toast.error("Could not register. Try again later!");
}

function* handleRegisterSuccess() {
  yield put(updateIsLoadingAuth({ status: false }));
}

function* handleLogin() {
  yield put(updateIsLoadingAuth({ status: true }));
}

function* handleLoginFail() {
  yield put(updateIsLoadingAuth({ status: false }));
  toast.error("Could not log in. Try again later!");
}

function* handleLoginSuccess() {
  yield put(updateIsLoadingAuth({ status: false }));
}

function* handleResetPassword() {
  yield put(updateIsLoadingAuth({ status: true }));
}

function* handleResetPasswordFail() {
  yield put(updateIsLoadingAuth({ status: false }));
  toast.error("Could not reset your password. Try again later!");
}

function* handleResetPasswordSuccess() {
  yield put(updateIsLoadingAuth({ status: false }));
}

function* handleUploadImageServer() {
  yield put(updateIsUploadingImage({ status: true }));
}

function* handleUploadImageServerFail() {
  yield put(updateIsUploadingImage({ status: false }));
  toast.error("Could not change image");
}

function* handleUploadImageServerSuccess(action: any) {
  yield put(updateIsUploadingImage({ status: false }));
  yield put(setUpdatedUserPicture({ status: true }));
  yield put(updateUserData({ picture: action.payload.data.data.url }));
  toast.success("New picture was successfully uploaded!");
}

export default authSagas;
