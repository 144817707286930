import { SubscriptionPlan } from "./subscription";

export enum HistoryItemStatus {
  paid = "paid",
  failed = "void",
  draft = "draft",
  open = "open",
}

export interface InvoiceHistoryItem {
  id: string;
  period_end: number;
  subscription: SubscriptionPlan;
  total: number;
  status: HistoryItemStatus;
  hosted_invoice_url: string;
  lines: {
    data: {
      plan: {
        product: {
          id: string;
        };
      };
    }[];
  };
}
