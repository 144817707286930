import { Button, Dropdown, TextField } from "@maestro-org/ui-kit";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Theme, Typography, styled, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { updatePopup } from "../../redux/actions/popupsActions";
import { getAddNewProjectDialog } from "../../redux/reducers/popupsReducer";
import { getAllprojects } from "../../redux/reducers/projectsReducer";
import { createProjectsServer } from "../../redux/actions/projectsActions";

import { getCreateProjectFields, initialValuesCreateProject } from "../../forms/createProject/form";
import { createProjectSchema } from "../../forms/createProject/validation";
import { CreateProjectFormValues } from "../../forms/createProject/types";

import Dialog from "./Dialog";

import { Popups } from "../../types/popups";
import { NewEntryField, NewEntryFieldTypes } from "../../types/newEntry";
import { Network, networkLabels } from "../../types/project";

const AddNewProjectDialog = () => {
  const theme = useTheme();

  const onSubmit = (values: CreateProjectFormValues) => {
    const network = Object.keys(networkLabels).find(
      (key) => networkLabels[key as keyof typeof networkLabels] === values.network[0],
    );
    if (!network) return;
    addProject(values.name, network as Network);
  };

  const addProject = (name: string, network: Network) => {
    handleClose();
    formik.resetForm();
    dispatch(createProjectsServer({ name, network }));
  };

  const isOpen = useSelector(getAddNewProjectDialog);

  const formik = useFormik({
    initialValues: initialValuesCreateProject,
    validationSchema: createProjectSchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit,
  });

  const { values, setFieldValue, setFieldTouched, errors, touched, handleChange, handleBlur, handleSubmit } = formik;

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(updatePopup({ popup: Popups.addNewProject, status: false }));
  };

  const handleDropdownChange = (value: unknown, name: string) => {
    setFieldValue(name, value, true);
  };

  const handleDropdownBlur = (name: string) => {
    setFieldTouched(name, true, true);
  };

  const checkError = (name: string) => !!errors[name as keyof typeof errors] && touched[name as keyof typeof touched];

  const getHelperText = (name: string) =>
    errors[name as keyof typeof errors] && touched[name as keyof typeof touched]
      ? errors[name as keyof typeof errors]
      : undefined;

  const getField = (field: NewEntryField) => ({
    [NewEntryFieldTypes.text]: (
      <TextField
        label={field.label}
        variant="outlined"
        name={field.name}
        value={values[field.name as keyof typeof values]}
        onChange={handleChange}
        placeholder={field.placeholder}
        onBlur={handleBlur}
        error={checkError(field.name) as boolean}
        helperText={getHelperText(field.name)}
      />
    ),
    [NewEntryFieldTypes.select]: (
      <StyledSelect
        label={field.label}
        name={field.name}
        fullWidth
        onBlur={() => handleDropdownBlur(field.name)}
        value={values[field.name as keyof typeof values] as any}
        placeholder={field.placeholder}
        onChange={(value) => handleDropdownChange(value, field.name)}
        options={field.options || []}
        error={checkError(field.name) as boolean}
        helperText={(getHelperText(field.name) as string) || ("" as string)}
        MenuProps={{
          PaperProps: {
            style: {
              ...getMenuStyle(theme, checkError(field.name) as boolean),
            },
          },
        }}
      />
    ),
  });

  return (
    <Dialog open={isOpen} handleClose={handleClose}>
      <Form onSubmit={handleSubmit}>
        <Typography color="grey.A200" variant="h5">
          Add new project
        </Typography>
        <FormFields>{getCreateProjectFields.map((field: NewEntryField) => getField(field)[field.type])}</FormFields>
        <Actions>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <Button type="submit" onMouseDown={(e) => e.preventDefault()}>
            Save project
          </Button>
        </Actions>
      </Form>
    </Dialog>
  );
};

const Form = styled("form")({
  display: "flex",
  flexDirection: "column",
  rowGap: "40px",
});

const FormFields = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "20px",
});

const StyledSelect = styled(Dropdown)(({ theme }) => ({
  "& .MuiSelect-select": {
    backgroundColor: theme.palette.common.white,
  },
}));

const Actions = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "12px",
});

const CancelButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.borderRadius.sm,
  background: theme.palette.grey[50],
  color: theme.palette.grey["A200"],

  "&:hover": {
    background: theme.palette.grey[50],
    color: theme.palette.grey["A200"],
  },
}));

const getMenuStyle = (theme: Theme, error?: boolean, width?: number) => ({
  width: width ? `${width}px` : "auto",
  background: theme.palette.common.white,
  borderRadius: theme.borderRadius.sm,
  boxShadow: "none",
  border: `2px solid ${error ? theme.palette.dropdown.border.error : theme.palette.dropdown.border.main}`,
  transform: "translateY(5px)",
});

export default AddNewProjectDialog;
