import React, { useEffect, useState } from "react";
import { styled, TableContainer, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Loader, TextField } from "@maestro-org/ui-kit";

import TransactionMonitoringRow from "./TransactionMonitoringRow";
import TransactionFilter from "./TransactionFilter";
import TablePagination from "../../../components/Table/TablePagination";
import { SearchIcon, TransactionMonitoringIcon, EmptyTransactionsIcon } from "../../../components/Icons";

import { getAllTransactions, getTransactionsLoading } from "../../../redux/reducers/transactionsReducer";

import { RowType } from "../../../types/transaction";
import { EntryType } from "../../../types/table";
import { createRow } from "../../../lib/transactionMonitoringUtils";
import Container from "../../../components/Container/Container";

const TransactionMonitoring = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [transactions, setTransactions] = useState<RowType[]>([]);

  const allTransactions = useSelector(getAllTransactions);
  const isLoading = useSelector(getTransactionsLoading);

  const rowsPerPage = 5;

  const handleNextPage = () => setCurrentPage(currentPage + 1);

  const handlePrevPage = () => setCurrentPage(currentPage - 1);

  const handlePageChenge = (newPage: number) => setCurrentPage(newPage);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);
  };

  // const getCurrentPageSlice = (): RowType[] =>
  //   transactions.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);

  useEffect(() => {
    if (!allTransactions) return;
    setTransactions(allTransactions.map((transaction, index) => createRow(index, transaction)));
  }, [allTransactions]);

  useEffect(() => {
    setTransactions(
      allTransactions
        .filter((item) => item.project_name.toLowerCase().includes(search.toLowerCase()))
        .map((transaction, index) => createRow(index, transaction)),
    );
  }, [search]);

  return (
    <Wrapper>
      <Container>
        <Top>
          <TitleWrapper>
            <TitleWithAndornment>
              <TransactionMonitoringIcon />
              <Typography color="grey.A200" variant="h5">
                Transaction Monitoring
              </Typography>
            </TitleWithAndornment>
            <Subtitle color="grey.500" variant="paragraphSmall">
              Real-time transaction monitoring & management system. Never lose sight of a transaction again.
            </Subtitle>
          </TitleWrapper>
          <SearchTextfield
            variant="outlined"
            value={search}
            onChange={handleSearchChange}
            startIcon={<SearchIcon />}
            placeholder="Search projects"
          />
        </Top>

        <TransactionFilter page={currentPage} />
      </Container>

      {isLoading ? (
        <Container>
          <EmptyMessageWrapper>
            <Loader size={70} />
          </EmptyMessageWrapper>
        </Container>
      ) : (
        <>
          {transactions.length ? (
            <StyledTableContainer>
              <div>
                {transactions.map((row) => (
                  <TransactionMonitoringRow {...row} key={row.id} />
                ))}
              </div>
            </StyledTableContainer>
          ) : (
            <Container>
              <EmptyMessageWrapper>
                <EmptyIconWrapper>
                  <EmptyTransactionsIcon />
                </EmptyIconWrapper>
                <Typography color="grey.300" variant="subtitle">
                  No results found
                </Typography>
                <Typography color="grey.300" variant="paragraphMedium">
                  Try making your first transactions in order to find what you’re looking for.
                </Typography>
              </EmptyMessageWrapper>
            </Container>
          )}
        </>
      )}
      <Container>
        <TablePagination
          page={currentPage}
          rowsPerPage={rowsPerPage}
          entries={transactions as unknown as EntryType[]}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          handlePageChenge={handlePageChenge}
        />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled("div")(({ theme }) => ({
  paddingTop: "72px",
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.down("md")]: {
    paddingTop: "56px",
  },

  [theme.breakpoints.down("sm")]: {
    paddingTop: "32px",
  },
}));

const Top = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "space-between",

  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
    rowGap: "32px",

    "& .MuiInputBase-root": {
      padding: 0,
    },
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflow: "auto hidden",
  paddingBottom: "12px",

  width: "1552px",
  margin: "0 auto",
  padding: "0 50px",
  maxWidth: "100%",

  [theme.breakpoints.down("xl")]: {
    width: "1300px",
  },

  [theme.breakpoints.down("lg")]: {
    width: "1100px",
  },

  [theme.breakpoints.down("md")]: {
    padding: "0 5%",
  },

  "&::-webkit-scrollbar ": {
    height: "6px",
  },
  "&::-webkit-scrollbar-track ": {
    background: theme.palette.grey[50],
    borderRadius: "4px",
    margin: "0 50px",
  },
  "&::-webkit-scrollbar-thumb ": {
    background: theme.palette.grey.A200,
    borderRadius: "13px",
  },
}));

const TitleWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "12px",
  maxWidth: "100%",
});

const TitleWithAndornment = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "12px",

  "& svg": {
    display: "block",
    width: "40px",
    height: "40px",
  },
});

const Subtitle = styled(Typography)({
  width: "400px",
  maxWidth: "100%",
});

const SearchTextfield = styled(TextField)(({ theme }) => ({
  width: "200px",

  "& .MuiOutlinedInput-root": {},

  "& > div > input": {
    padding: "12.5px 14px 12.5px 0",
  },
  "& > div > fieldset": {
    borderStyle: "none !important",
    background: "transparent !important",
  },

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    maxWidth: "none",
  },
}));

const EmptyMessageWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  rowGap: "12px",
  background: theme.palette.common.white,
  height: "397px",
  // padding: "89px 0 100px",
}));

const EmptyIconWrapper = styled("div")({
  paddingBottom: "20px",
});

export default TransactionMonitoring;
