import { IconButton } from "@maestro-org/ui-kit";
import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";

import SettingsCard from "./SettingsCard";
import { OpenInNewTabIcon } from "../../../components/Icons";
import HistoryStatusChip from "../../../components/Chip/HistoryStatusChip";

import { HistoryItemStatus, InvoiceHistoryItem } from "../../../types/invoiceHistory";
import { SubscriptionPlan } from "../../../types/subscription";
import { useDispatch, useSelector } from "react-redux";
import { getBillingInvoicesServer } from "../../../redux/actions/userActions";
import {
  getInvoicesHistory,
  getSubscriptionServices,
  getUserSubscriptions,
} from "../../../redux/reducers/usersReducer";
import { format } from "date-fns";

const getInvoiceDate = (timestapm: number) => {
  const date = new Date(timestapm * 1000);
  return format(date, "MMMM d, yyyy");
};

const BillingInvoiceHistory = () => {
  const [showMore, setShowMore] = useState(false);

  const dispatch = useDispatch();

  const invoices = useSelector(getInvoicesHistory);
  const subscriptions = useSelector(getUserSubscriptions);

  const handleShowMore = () => setShowMore(!showMore);

  useEffect(() => {
    if (invoices.length < 10) setShowMore(true);

    dispatch(getBillingInvoicesServer());
  }, []);

  const getSubscription = (invoiceItem: InvoiceHistoryItem) => {
    if (!subscriptions.length || !invoiceItem.lines.data.length) return SubscriptionPlan.artist;
    const productId = invoiceItem.lines.data[0].plan.product.id;
    const plan = subscriptions.find((subscription) => subscription.stripe_product_id === productId)?.subscription_name;
    return plan || SubscriptionPlan.artist;
  };

  return (
    <SettingsCard>
      <Top>
        <Typography variant="h6" color="grey.A200">
          Invoice history
        </Typography>
      </Top>
      <TableContainer isEmpty={!invoices.length} component={StyledPaper}>
        <StyledTable>
          <StyledTableHead>
            <TableRow>
              <HeaderTableCell>Date</HeaderTableCell>
              <HeaderTableCell align="center">Subscription</HeaderTableCell>
              <HeaderTableCell align="center">Price</HeaderTableCell>
              <HeaderTableCell align="left">Status</HeaderTableCell>
              <HeaderTableCell></HeaderTableCell>
            </TableRow>
          </StyledTableHead>
          <StyledTableBody>
            {(showMore ? invoices : invoices.slice(0, 9)).map((row) => (
              <TableRow key={row.id}>
                <StyledTableCell style={{ width: 160 }}>{getInvoiceDate(row.period_end)}</StyledTableCell>
                <StyledTableCell align="center">{getSubscription(row) as string}</StyledTableCell>
                <StyledTableCell style={{ width: 160 }} align="center">
                  ${(row.total / 100).toFixed(2)}
                </StyledTableCell>
                <StyledTableCell>
                  <HistoryStatusChip status={row.status} />
                </StyledTableCell>
                <StyledTableCell style={{ width: 20 }} align="right">
                  {row.hosted_invoice_url ? (
                    <a href={row.hosted_invoice_url} target="_blank" rel="noreferrer">
                      <ActiveIconButton>
                        <OpenInNewTabIcon />
                      </ActiveIconButton>
                    </a>
                  ) : (
                    <DisabledIconButton disabled>
                      <OpenInNewTabIcon />
                    </DisabledIconButton>
                  )}
                </StyledTableCell>
              </TableRow>
            ))}
          </StyledTableBody>
        </StyledTable>
      </TableContainer>
      {invoices.length >= 10 && (
        <Bottom>
          <LoadMoreWrapper onClick={handleShowMore}>
            <Typography color="primary.main" variant="paragraphMedium">
              {showMore ? "Hide" : "Load more"}
            </Typography>
          </LoadMoreWrapper>
        </Bottom>
      )}
    </SettingsCard>
  );
};

const Top = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const StyledTable = styled(Table)(({ theme }) => ({
  borderCollapse: "separate",
  borderSpacing: "0",

  [theme.breakpoints.down("md")]: {
    minWidth: "400px",
  },
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const StyledTableBody = styled(TableBody)(({ theme }) => ({}));

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "isEmpty",
})<{ isEmpty: boolean }>(({ isEmpty, theme }) => ({
  boxShadow: "none",
  overflow: "visible !important",

  [theme.breakpoints.down("md")]: {
    overflow: isEmpty ? "hidden !important" : "auto hidden !important",
    paddingBottom: "20px",

    "&::-webkit-scrollbar ": {
      height: "4px",
    },
    "&::-webkit-scrollbar-track ": {
      background: theme.palette.grey[50],
    },
    "&::-webkit-scrollbar-thumb ": {
      background: theme.palette.grey.A200,
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "11.5px 0",
  ...theme.typography.paragraphSmall,

  [theme.breakpoints.down("sm")]: {
    borderBottom: "none",
    padding: "4px 0",
  },
}));

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  padding: "0 0 11.5px",
  ...theme.typography.article,
  textTransform: "uppercase",
  color: theme.palette.grey[400],
}));

const ActiveIconButton = styled(IconButton)({
  background: "transparent",

  "&:hover": {
    background: "transparent",
  },
});

const DisabledIconButton = styled(IconButton)(({ theme }) => ({
  background: "transparent",

  "&:hover": {
    background: "transparent",
  },

  "& path": {
    fill: theme.palette.grey[200],
  },
}));

const Bottom = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const LoadMoreWrapper = styled("div")({
  cursor: "pointer",
});

export default BillingInvoiceHistory;
