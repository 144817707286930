export enum Network {
  Mainnet = "mainnet",
  Preprod = "preprod",
  Preview = "preview",
  // Testnet = "testnet",
}

export const networkLabels = {
  [Network.Mainnet]: "Mainnet",
  [Network.Preprod]: "Preprod",
  [Network.Preview]: "Preview",
};

export interface Project {
  id: string;
  name: string;
  network: Network;
  "24_hour_requests": number;
  created_at: string;
  api_key: string;
}

// export enum MetricsName {
//   total_requests_24_hour = "total_requests_24_hour",
//   success_pct_24_hour = "success_pct_24_hour",
//   invalid_requests_24_hour = "invalid_requests_24_hour",
//   median_response_10_min = "median_response_10_min",
// }

export interface MetricsItemBucket {
  count: number;
  timestamp: string;
}

interface Latency {
  value: number;
  timestamp: string;
}

interface MetricsData {
  buckets: MetricsItemBucket[];
  count: number;
  failed: {
    buckets: MetricsItemBucket[];
    count: number;
  };
  latency: Latency[];
  succeeded_pct: {
    buckets: { value: number; timestamp: string }[];
    value: number;
  };
}

export interface MetricsItem {
  requests_last_1h: MetricsData;
  requests_last_24h: MetricsData;
}
export enum ProjectStateModules {
  metrics = "metrics",
  queryDistribution = "queryDistribution",
  requestVolume = "requestVolume",
}

export interface ResponseSample {
  status_code: number;
  content_type: string;
  schema: string;
}

export enum RequestMethod {
  POST = "POST",
  PUT = "PUT",
  GET = "GET",
  DELETE = "DELETE",
}

export interface QueryDistributionItem {
  // endpoint_path: string;
  // endpoint_name: string;
  // endpoint_method: RequestMethod;
  // api_reference: string;
  host: string;
  route: string;
  usage: {
    requests_last_1h: number;
    requests_last_6h: number;
    requests_last_12h: number;
    requests_last_24h: number;
    requests_last_7d: number;
  };
  verb: string;
  // response_samples: ResponseSample[];
}

export interface RequestVolume {
  day: {
    buckets: MetricsItemBucket[];
    count: number;
  };
  month: {
    buckets: MetricsItemBucket[];
    count: number;
  };
  week: {
    buckets: MetricsItemBucket[];
    count: number;
  };
  year: {
    buckets: MetricsItemBucket[];
    count: number;
  };
}
