import { AuthField } from "../../types/auth";
import { LoginFields } from "./types";

export const initialValuesLogin = {
  [LoginFields.email]: "",
  [LoginFields.password]: "",
};

export const loginFields: AuthField[] = [
  {
    type: "email",
    label: "Email address",
    name: LoginFields.email,
    placeholder: "Enter your email address",
    fullwidth: true,
    autoComplete: "email",
  },
  {
    type: "password",
    label: "Password",
    name: LoginFields.password,
    placeholder: "***********",
    fullwidth: true,
    autoComplete: "current-password",
  },
];
