export enum SettingsPages {
  accountDetails = "accountDetails",
  credentials = "credentials",
  overview = "overview",
  plans = "plans",
  billing = "billing",
}

export enum CheckoutSteps {
  orderSummary = "orderSummary",
  checkout = "checkout",
  waitingForPayment = "waitingForPayment",
  failure = "failure",
  success = "success",
}
