import { toast } from "react-toastify";
import { takeEvery, put } from "redux-saga/effects";
import {
  GET_METRICS_OVERVIEW_SERVER,
  GET_QUERY_DISTRIBUTION_SERVER,
  GET_SINGLE_PROJECT_REQUEST_VOLUME,
  updateMetricsLoading,
  updateProjectMetrics,
  updateQueryDistribution,
  updateQueryDistributionLoading,
  updateSingleRequestVolume,
  updateSingleRequestVolumeLoading,
} from "../actions/singleProjectActions";

const singleProjectSagas = [
  takeEvery(GET_METRICS_OVERVIEW_SERVER, handleGetMetricsOverview),
  takeEvery(`${GET_METRICS_OVERVIEW_SERVER}_SUCCESS`, handleGetMetricsOverviewSuccess),
  takeEvery(`${GET_METRICS_OVERVIEW_SERVER}_FAIL`, handleGetMetricsOverviewFail),

  takeEvery(GET_QUERY_DISTRIBUTION_SERVER, handleGetQueryDistribution),
  takeEvery(`${GET_QUERY_DISTRIBUTION_SERVER}_SUCCESS`, handleGetQueryDistributionSuccess),
  takeEvery(`${GET_QUERY_DISTRIBUTION_SERVER}_FAIL`, handleGetQueryDistributionFail),

  takeEvery(GET_SINGLE_PROJECT_REQUEST_VOLUME, handleGetRequestVolume),
  takeEvery(`${GET_SINGLE_PROJECT_REQUEST_VOLUME}_SUCCESS`, handleGetRequestVolumeSuccess),
  takeEvery(`${GET_SINGLE_PROJECT_REQUEST_VOLUME}_FAIL`, handleGetRequestVolumeFail),
];

function* handleGetMetricsOverview() {
  yield put(updateMetricsLoading({ status: true }));
}

function* handleGetMetricsOverviewSuccess(action: any) {
  yield put(updateMetricsLoading({ status: false }));
  yield put(updateProjectMetrics({ metrics: action.payload.data }));
}

function* handleGetMetricsOverviewFail() {
  yield put(updateMetricsLoading({ status: false }));
  yield toast.error("Could not get metrics-overview data");
}

function* handleGetQueryDistribution() {
  yield put(updateQueryDistributionLoading({ status: true }));
}

function* handleGetQueryDistributionSuccess(action: any) {
  yield put(updateQueryDistributionLoading({ status: false }));
  yield put(updateQueryDistribution({ data: action.payload.data }));
}

function* handleGetQueryDistributionFail() {
  yield put(updateQueryDistributionLoading({ status: false }));
  toast.error("Could not get query distribution data");
}

function* handleGetRequestVolume() {
  yield put(updateSingleRequestVolumeLoading({ status: true }));
}

function* handleGetRequestVolumeFail() {
  yield put(updateSingleRequestVolumeLoading({ status: false }));
  toast.error("Could not get request volume data!");
}

function* handleGetRequestVolumeSuccess(action: any) {
  yield put(updateSingleRequestVolumeLoading({ status: false }));
  yield put(updateSingleRequestVolume({ data: action.payload.data }));
}

export default singleProjectSagas;
