import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { styled, Typography } from "@mui/material";

import { getWebhooksServer } from "../../redux/actions/webhooksActions";

import MainNavigation, { NavItems } from "../../components/Navigation/MainNavigation";
import Footer from "../Home/components/Footer";
import Container from "../../components/Container/Container";
import TransactionEventsListening from "./components/TransactionEventsListening";
import TransactionMonitoring from "./components/TransactionMonitoring";
import { DocumentationIcon } from "../../components/Icons";

import { getAuthData } from "../../redux/reducers/authReducer";
import { getProjectsServer } from "../../redux/actions/projectsActions";
import withPrivateRoute from "../../hooks/withPrivateRoute";

const TransactionsPage = () => {
  const userData = useSelector(getAuthData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!Object.keys(userData).length) return;
    dispatch(getProjectsServer({}));
    dispatch(getWebhooksServer({ project_ids: [] }));
  }, []);

  return (
    <Wrapper>
      <MainNavigation activeItem={NavItems.transactions} actions={<TransactionPageNavActions />} />
      <TransactionMonitoring />
      <TransactionEventsListening />
      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  background: "#F5F5F5",
});

const TransactionPageNavActions = () => (
  <>
    <a href="https://docs.gomaestro.org/docs/Getting-started/Sign-up-login" target="_blank" rel="noreferrer">
      <DocumentationWrapper>
        <DocumentationIcon />
        <Typography variant="paragraphMedium" color="textColor.dark">
          Documentation
        </Typography>
      </DocumentationWrapper>
    </a>
  </>
);

const DocumentationWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "8px",
});

export default withPrivateRoute(TransactionsPage);
