import { useSelector } from "react-redux";
import { styled, Typography } from "@mui/material";
import React, { useState } from "react";

import { COLORS_LIGHT_THEME } from "../../../types/pieChartColors";

import { getProjectQueryDistribution } from "../../../redux/reducers/singleProjectReducer";

import PieChart from "../../../components/PieChart/PieChart";
import Tabs from "../../../components/Tabs/Tabs";
import { QueryDistributionItem } from "../../../types/project";
import { TokenomicsPoint } from "../../../types/tokenomicsTypes";
import { CodeIcon } from "../../../components/Icons";
import { Chip } from "@maestro-org/ui-kit";

export enum TabsTypes {
  oneHour = "requests_last_1h",
  sixHours = "requests_last_6h",
  twelveHours = "requests_last_12h",
  twentyFourHours = "requests_last_24h",
  sevenDays = "requests_last_7d",
}

const tabsPanelOptions = [
  {
    value: TabsTypes.oneHour,
    label: "1hr",
  },
  {
    value: TabsTypes.sixHours,
    label: "6hr",
  },
  {
    value: TabsTypes.twelveHours,
    label: "12hr",
  },
  {
    value: TabsTypes.twentyFourHours,
    label: "24hr",
  },
  {
    value: TabsTypes.sevenDays,
    label: "7d",
  },
];

const parsePieChartData = (queries: QueryDistributionItem[], tabValue: TabsTypes): TokenomicsPoint[] => {
  if (!queries.length) return [];
  return queries
    .map((query: QueryDistributionItem) => {
      const queryRoute = query.route;
      const regex = /^\/v\d+\//;
      const cleanedRoute = queryRoute.replace(regex, "/");
      const endpointName = cleanedRoute in endpoints ? endpoints[cleanedRoute as keyof typeof endpoints] : queryRoute;
      return {
        label: endpointName.charAt(0).toUpperCase() + endpointName.slice(1),
        count: query.usage[tabValue],
        route: cleanedRoute,
      };
    })
    .sort((a: any, b: any) => b.count - a.count)
    .filter((item) => !!item.count);
};

interface Props {
  selectSection: string;
  handleSelectSection: (label: string) => void;
}

const PieChartCard = ({ selectSection, handleSelectSection }: Props) => {
  const [tabValue, setTabValue] = useState<TabsTypes>(TabsTypes.sixHours);

  const queryDistribution = useSelector(getProjectQueryDistribution);

  const data = parsePieChartData(queryDistribution, tabValue);

  const handleTabsChange = (event: React.SyntheticEvent, newValue: TabsTypes) => {
    setTabValue(newValue as TabsTypes);
  };

  return (
    <Wrapper isEmpty={!data.length}>
      {!data.length && (
        <EmptyWrapper>
          <Top>
            <TopBlock>
              <Typography variant="h5" color="grey.A200">
                Query Distribution
              </Typography>
            </TopBlock>
            <a href="https://docs.gomaestro.org/docs/category/rest-api-reference" target="_blank" rel="noreferrer">
              <APIRefChip
                color="custom"
                hexColor="#FFFFFF"
                label={
                  <ChipContent>
                    <CodeIcon />
                    <Typography color="grey.A200" variant="paragraphMedium">
                      API Reference
                    </Typography>
                  </ChipContent>
                }
              />
            </a>
            <DesktopOnly>
              <TopBlock>
                <Tabs options={tabsPanelOptions} value={tabValue} onChange={handleTabsChange} variant="fullWidth" />
              </TopBlock>
            </DesktopOnly>
          </Top>
          <MobileOnly>
            <TopBlock>
              <Tabs options={tabsPanelOptions} value={tabValue} onChange={handleTabsChange} variant="fullWidth" />
            </TopBlock>
          </MobileOnly>
        </EmptyWrapper>
      )}
      {tabValue && (
        <PieChart
          timeFrameKey={tabValue}
          data={data}
          image={"/logos/PieChart/maestroWhite.svg"}
          color={COLORS_LIGHT_THEME}
          selectSection={selectSection}
          handleSelectSection={handleSelectSection}
          tabsPanelOptions={tabsPanelOptions}
          tabValue={tabValue}
          handleTabsChange={handleTabsChange}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled("div")<{ isEmpty: boolean }>(({ theme, isEmpty }) => ({
  padding: "40px 25px 40px 40px",
  backgroundColor: theme.palette.grey[50],
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  gap: "51px",
  // maxWidth: "956px",
  width: "100%",

  [theme.breakpoints.down("xl")]: {
    maxWidth: "100%",
  },

  [theme.breakpoints.down("md")]: {
    padding: "32px 32px 40px",
  },

  [theme.breakpoints.down("sm")]: {
    padding: "32px",
  },
}));

const EmptyWrapper = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: "30px",
  },
}));

const Top = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    rowGap: "20px",
  },
}));

const DesktopOnly = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

const MobileOnly = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    display: "none",
  },
}));

const TopBlock = styled("div")({
  width: "300px",
});

const APIRefChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.borderRadius.sm,
  padding: "5px 20px",

  "& svg": {
    display: "block",
  },
}));

const ChipContent = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "8px",
});

export default PieChartCard;

const endpoints = {
  "/accounts/:id": "stake account information",
  "/accounts/:id/addresses": "stake account addresses",
  "/accounts/:id/assets": "stake account assets",
  "/accounts/:id/history": "stake account history",
  "/accounts/:id/rewards": "stake account rewards",
  "/accounts/:id/updates": "stake account updates",
  "/addresses/utxos": "UTxOs at multiple addresses",
  "/addresses/:id/utxos": "UTxOs at an address",
  "/addresses/:id/utxo_refs": "UTxO references at an address",
  "/addresses/:id/decode": "Decoded receiving address information",
  "/addresses/:id/transactions/count": "Transaction count of an address",
  "/assets/:id": "Native asset information",
  "/assets/:id/addresses": "Native asset addresses",
  "/assets/:id/txs": "Native asset transactions",
  "/assets/:id/updates": "Native asset updates",
  "/assets/policy/:id": "Minting policy information",
  "/assets/policy/:id/addresses": "Minting policy addresses",
  "/assets/policy/:id/txs": "Minting policy transactions",
  "/assets/policy/:id/utxos": "Minting policy UTxOs",
  "/chain-tip": "chain tip details",
  "/era-history": "era history",
  "/protocol-params": "network protocol parameters",
  "/system-start": "system start time",
  "/datum/:id": "datum hash lookup",
  "/epochs/current": "current epoch",
  "/epochs/:id/info": "epoch details",
  "/pools": "registered stake pools",
  "/pools/:id/blocks": "Stake pool blocks",
  "/pools/:id/delegators": "stake pool delegators",
  "/pools/:id/history": "Stake pool history",
  "/pools/:id/info": "Stake pool details",
  "/pools/:id/metadata": "stake pool metadata",
  "/pools/:id/relays": "stake pool relays",
  "/pools/:id/updates": "stake pool updates",
  "/scripts/:id": "script of a script hash",
  "/transactions/:id/cbor": "CBOR bytes of a transaction",
  "/transactions/:id/outputs/:id/address": "address of a transaction output reference",
  "/transactions/:id/outputs/:id/utxo": "transaction output of an output reference",
  "/txmanager": "submit transaction",
  "/txmanager/history": "transaction history",
  "/txmanager/:id/state": "transaction state",
};
