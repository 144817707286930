import { styled } from "@mui/material";
import React from "react";

import { SettingsPages } from "../../types/settings";

import SettingsLayout from "../../layouts/SettingsLayout";

import AccountDetails from "./components/AccountDetails";
import EmailNotification from "./components/EmailNotification";
import UserCredentials from "./components/UserCredentials";
import DeleteAccount from "./components/DeleteAccount";

import withPrivateRoute from "../../hooks/withPrivateRoute";

const AccountPage = () => {
  return (
    <SettingsLayout
      path={[
        { title: "Settings", href: "/" },
        { title: "Account", href: "/" },
        { title: "Account details", href: "/" },
      ]}
      active={SettingsPages.accountDetails}
    >
      <OuterWrapper>
        <AccountDetails />
        {/* <EmailNotification /> */}
        <UserCredentials />
        <DeleteAccount />
      </OuterWrapper>
    </SettingsLayout>
  );
};

const OuterWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "32px",
}));

export default withPrivateRoute(AccountPage);
