import { Auth0Error, Auth0UserProfile, Management } from "auth0-js";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// import { createUserServer } from "../redux/actions/userActions";
import { setIsUpdatingUserInfo, setUpdatedUserPicture, updateUserData } from "../redux/actions/authActions";
import { getAuth0FuncData, getAuthData, getIsUserPictureUpdated } from "../redux/reducers/authReducer";

import { useEffect } from "react";

interface UpdateUserInfoProps {
  firstName?: string;
  lastName?: string;
  picture?: string;
}

export const useUserMetadata = () => {
  const userData = useSelector(getAuthData);
  const auth0Data = useSelector(getAuth0FuncData);
  const isPictureUpdated = useSelector(getIsUserPictureUpdated);
  const dispatch = useDispatch();

  const getAuthManagement = () =>
    new Management({
      domain: `${process.env.REACT_APP_AUTH0_DOMAIN}`,
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
      clientSecret: process.env.REACT_APP_AUTH0_CLIENT_SECRET,
      scope: "read:current_user update:current_user_metadata",
      token: auth0Data.accessToken,
      audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
    });

  const updateUserInfo = async (data: UpdateUserInfoProps, showToasts: boolean) => {
    getAuthManagement().patchUserMetadata(userData.sub, data, (error: Auth0Error | null, res: Auth0UserProfile) => {
      if (error) {
        console.log("UPDATING METADATA ERROR", error);
        if (showToasts) {
          toast.error("Could not update user info. Try again later!");
        }
        dispatch(updateUserData(userData));
        dispatch(setIsUpdatingUserInfo({ status: false }));
        return;
      }
      console.log("UPDATING METADATA SUCCESS", res);
      if (showToasts) toast.success("User info was successfully updated");
      console.log(res);
      // dispatch(createUserServer({ email: res?.email || "", auth0_user_id: userData.sub }));
      dispatch(updateUserData({ ...res, ...res.user_metadata }));
      dispatch(setIsUpdatingUserInfo({ status: false }));
    });
  };

  const getLoginMetadata = () => {
    if (!userData.sub) return;
    getAuthManagement().getUser(userData.sub, (error: Auth0Error | null, res: Auth0UserProfile) => {
      if (error) {
        console.log("Could not get user metadata", error);
        return;
      }
      const meta = res.user_metadata;
      console.log("User metadata", meta);
      if (meta) {
        dispatch(updateUserData({ ...meta }));
        return;
      }
      updateUserInfo({ firstName: userData.firstName, lastName: userData.lastName, picture: userData.picture }, false);
    });
  };

  useEffect(() => {
    if (!isPictureUpdated) return;
    updateUserInfo({ picture: userData.picture }, false);
    dispatch(setUpdatedUserPicture({ status: false }));
  }, [isPictureUpdated]);

  return { updateUserInfo, getLoginMetadata };
};
