import { IBillingInfo, ICard, StripeItem } from "../../types/auth";
import { StripeCustomer } from "../../types/stripeCustomer";
import {
  CHANGE_SUBSCRIPTION_UPDATED,
  UPDATE_BILLING_DATA,
  UPDATE_BILLING_DATA_LOADING,
  UPDATE_CLIENT_SECRET,
  UPDATE_CURRENT_PAYMENT_METHOD,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_LOADING,
  UPDATE_LOADING_PAYMENT_METHODS,
} from "../actions/stripeActions";
import { StoreType } from "../types/store.types";

export interface stripeStateType {
  isLoading: boolean;
  paymentMethods: StripeItem[];
  activeMethodId: string;
  clientSecret: string;
  loadingMethodsIds: string[];
  subscriptionUpdated: {
    isUpdated: boolean;
    success: boolean;
  };
  customer?: StripeCustomer;
  customerLoading: boolean;
}

const stripeInitialState: stripeStateType = {
  isLoading: true,
  paymentMethods: [],
  activeMethodId: "",
  clientSecret: "",
  loadingMethodsIds: [],
  subscriptionUpdated: {
    isUpdated: false,
    success: false,
  },
  customer: undefined,
  customerLoading: false,
};

const stripeReducer = (state = stripeInitialState, action: any) => {
  switch (action.type) {
    case UPDATE_BILLING_DATA: {
      return {
        ...state,
        paymentMethods: [...action.payload.data.paymentMethods],
      };
    }
    case UPDATE_BILLING_DATA_LOADING: {
      return {
        ...state,
        isLoading: action.payload.status,
      };
    }
    case UPDATE_CURRENT_PAYMENT_METHOD: {
      return {
        ...state,
        activeMethodId: action.payload.id,
      };
    }
    case UPDATE_CLIENT_SECRET: {
      return {
        ...state,
        clientSecret: action.payload.clientSecret,
      };
    }
    case UPDATE_LOADING_PAYMENT_METHODS: {
      return {
        ...state,
        loadingMethodsIds: action.payload.methodsIds,
      };
    }
    case CHANGE_SUBSCRIPTION_UPDATED: {
      return {
        ...state,
        subscriptionUpdated: action.payload.data,
      };
    }
    case UPDATE_CUSTOMER: {
      return {
        ...state,
        customer: action.payload.customer,
      };
    }
    case UPDATE_CUSTOMER_LOADING: {
      return {
        ...state,
        customerLoading: action.payload.status,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const getAllPaymentMethods = (state: StoreType) => state.stripe.paymentMethods;
export const getBillingDataLoading = (state: StoreType) => state.stripe.isLoading;
export const getActiveMethodId = (state: StoreType) => state.stripe.activeMethodId;
export const getClientSecret = (state: StoreType) => state.stripe.clientSecret;
export const getLoadingPaymentMethods = (state: StoreType) => state.stripe.loadingMethodsIds;
export const getIsSubscriptionUpdated = (state: StoreType) => state.stripe.subscriptionUpdated;
export const getStripeCustomer = (state: StoreType) => state.stripe.customer;
export const getStripeCustomerLoading = (state: StoreType) => state.stripe.customerLoading;

export default stripeReducer;
