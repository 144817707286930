import { AuthField } from "../../types/auth";
import { ResetPasswordFields } from "./types";

export const initialValuesResetPassword = {
  [ResetPasswordFields.email]: "",
};

export const resetPasswordFields: AuthField[] = [
  {
    type: "email",
    label: "Email address",
    name: ResetPasswordFields.email,
    placeholder: "Enter your email address",
    fullwidth: true,
  },
];
