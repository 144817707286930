import { styled, Typography } from "@mui/material";
import { Loader } from "@maestro-org/ui-kit";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { getSubscriptionPlan } from "../../../redux/reducers/authReducer";
import { getUserSubscriptionsLoading } from "../../../redux/reducers/usersReducer";

import PlanPackage from "./PlanPackage";

import { UserSubscriptions } from "../../../types/user";
import { SubscriptionPlan } from "../../../types/subscription";

interface Props {
  activePlan?: UserSubscriptions;
  packages: UserSubscriptions[];
  handleChangeActive: (title: string) => void;
}

const APIPackages = ({ activePlan, packages, handleChangeActive }: Props) => {
  const plan = useSelector(getSubscriptionPlan);
  const isLoading = useSelector(getUserSubscriptionsLoading);

  return (
    <Wrapper>
      <TitleWrapper>
        <Typography color="grey.A200" variant="h5">
          API Packages
        </Typography>
        <Typography color="grey.A200" variant="paragraphSmall">
          Currently subscribed to <PlanName>{plan}</PlanName>
        </Typography>
      </TitleWrapper>
      {isLoading ? (
        <Loader />
      ) : (
        <Content>
          <PackagesWrapper>
            {packages.map((item) => (
              <PlanPackage
                key={item.id}
                isActive={activePlan?.id === item.id}
                handleChangeActive={handleChangeActive}
                isMostPopular={item.subscription_name === SubscriptionPlan.composer}
                {...item}
              />
            ))}
          </PackagesWrapper>
          <Fade />
        </Content>
      )}
    </Wrapper>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "24px",
});

const TitleWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "12px",
});

const PlanName = styled("strong")({
  textTransform: "capitalize",
});

const Content = styled("div")({
  position: "relative",
});

const PackagesWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  columnGap: "36px",
  overflow: "auto hidden",
  padding: "16px 80px 40px 0",
  position: "relative",

  "&::-webkit-scrollbar": {
    height: "2px",
  },

  "&::-webkit-scrollbar-track": {
    backgroundColor: "#E6E6E6",
    height: "2px",
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#0F0311",
    borderRadius: "2px",
    height: "2px",
  },

  "&::-webkit-scrollbar-button": {
    display: "none",
  },

  "& > div:last-child": {
    // background: "radial-gradient(83.49% 100% at 50% 100%, #FFFFFF 0%, rgba(247, 229, 250, 0.18) 100%)",
  },

  [theme.breakpoints.down("xl")]: {
    columnGap: "26px",
  },
}));

const Fade = styled("div")(({ theme }) => ({
  position: "absolute",
  right: 0,
  top: 0,
  width: "136px",
  height: "450px",
  background: "linear-gradient(270deg, #F5F5F5 15.15%, rgba(245, 245, 245, 0) 77.52%)",
  pointerEvents: "none",

  [theme.breakpoints.down(1101)]: {
    width: "100px",
  },
}));

export default APIPackages;
