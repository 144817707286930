import { styled, Typography } from "@mui/material";
import React, { useState } from "react";

import { PathElement } from "../types/breadcrumpsPathTypes";
import { CheckoutSteps, SettingsPages } from "../types/settings";

import SettingsNavigation from "../components/Navigation/SettingsNavigation";
import SettingsSidebar from "../modules/Settings/components/SettingsSidebar";
import Breadcrumps from "../components/Breadcrumps/Breadcrumps";
import PlansRightSidebar from "../modules/Settings/components/PlansRightSidebar";
import PlansMobileCheckout from "../modules/Settings/components/PlansMobileCheckout";
import OrderSummaryMobile from "../modules/Settings/components/PlansStepsMobile/OrderSummaryMobile";
import CheckoutMobile from "../modules/Settings/components/PlansStepsMobile/CheckoutMobile";
import PaymentInProcessMobile from "../modules/Settings/components/PlansStepsMobile/PaymentInProcessMobile";
import PaymentFailMobile from "../modules/Settings/components/PlansStepsMobile/PaymentFailMobile";
import PaymentSuccessMobile from "../modules/Settings/components/PlansStepsMobile/PaymentSuccessMobile";
import { DocumentationIcon } from "../components/Icons";

import { pages } from "../lib/routeUtils";
import { UserSubscriptions } from "../types/user";

interface Props {
  activePlan?: UserSubscriptions;
  children: React.ReactNode;
  active: SettingsPages;
  path: PathElement[];
}

const SubscriptionPlansLayout = ({ children, active, path, activePlan }: Props) => {
  const [checkoutStep, setCheckoutStep] = useState<CheckoutSteps>();

  const handleChangeStep = (step?: CheckoutSteps) => setCheckoutStep(step);

  const getMobileContent = () => {
    switch (checkoutStep) {
      case CheckoutSteps.orderSummary: {
        return <OrderSummaryMobile activePlan={activePlan} handleChangeStep={handleChangeStep} />;
      }
      case CheckoutSteps.checkout: {
        return <CheckoutMobile handleChangeStep={handleChangeStep} activePlan={activePlan} />;
      }
      case CheckoutSteps.waitingForPayment: {
        return <PaymentInProcessMobile handleChangeStep={handleChangeStep} />;
      }
      case CheckoutSteps.success: {
        return <PaymentSuccessMobile />;
      }
      case CheckoutSteps.failure: {
        return <PaymentFailMobile handleBackClick={() => handleChangeStep()} activePlan={activePlan} />;
      }
      default: {
        return children;
      }
    }
  };

  return (
    <OuterWrapper>
      <SettingsSidebar active={active} items={sidebarItems} />
      <Wrapper>
        <SettingsNavigation path={path} actions={<SettingsNavActions />} />
        <ContentWrapper>
          <Content isWhiteBackground={!!checkoutStep}>
            <BreadcrumpsWrapper>
              <Breadcrumps path={path} />
            </BreadcrumpsWrapper>
            <DesktopOnly>{children}</DesktopOnly>
            <MobileOnly>{getMobileContent()}</MobileOnly>
          </Content>
          <PlansRightSidebar activePlan={activePlan} activeStep={checkoutStep} handleChangeStep={handleChangeStep} />
          {!checkoutStep && <PlansMobileCheckout handleNextStep={() => handleChangeStep(CheckoutSteps.orderSummary)} />}
        </ContentWrapper>
      </Wrapper>
    </OuterWrapper>
  );
};

const MobileOnly = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const DesktopOnly = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const OuterWrapper = styled("div")({
  display: "flex",
  height: "100vh",
  overflowY: "hidden",
});

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  overflowY: "hidden",
  flexGrow: 1,
  minWidth: 0,
});

const ContentWrapper = styled("div")({
  display: "flex",
  height: "100%",
});

const Content = styled("div")<{ isWhiteBackground: boolean }>(({ theme, isWhiteBackground }) => ({
  background: theme.palette.grey[50],
  minWidth: 0,
  display: "flex",
  padding: "56px 160px 110px 160px",
  flexDirection: "column",
  rowGap: "56px",
  flexGrow: 1,
  overflow: "hidden auto",

  "&::-webkit-scrollbar": {
    backgroundColor: "transparent",
    width: "8px",
  },

  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#babac0",
    borderRadius: "16px",
  },

  "&::-webkit-scrollbar-button": {
    display: "none",
  },

  [theme.breakpoints.down(1850)]: {
    padding: "56px 100px 110px 100px",
  },

  [theme.breakpoints.down(1750)]: {
    padding: "56px 60px 110px 60px",
  },

  [theme.breakpoints.down("lg")]: {
    paddingLeft: "60px",
  },

  [theme.breakpoints.down(1101)]: {
    padding: "24px",
  },

  [theme.breakpoints.down("md")]: {
    paddingBottom: "140px",
    background: isWhiteBackground ? theme.palette.common.white : theme.palette.grey[50],
  },

  [theme.breakpoints.down("sm")]: {
    padding: "24px 20px 140px",
    rowGap: "48px",
  },
}));

const BreadcrumpsWrapper = styled("div")(({ theme }) => ({
  [theme.breakpoints.up(1101)]: {
    display: "none",
  },
}));

const sidebarItems = [
  {
    title: "Account",
    pages: [
      { value: SettingsPages.accountDetails, href: pages.account(), title: "Account details" },
      // { value: SettingsPages.credentials, href: pages.credentials(), title: "Credentials" },
    ],
  },
  {
    title: "Subscription",
    pages: [
      { value: SettingsPages.overview, href: pages.subscriptionPlan(), title: "Overview" },
      { value: SettingsPages.plans, href: pages.settings(), title: "Plans" },
    ],
  },
  {
    title: "Invoices",
    pages: { value: SettingsPages.billing, href: pages.billing() },
  },
];

const SettingsNavActions = () => (
  <>
    <a href="https://docs.gomaestro.org/docs/Getting-started/Sign-up-login" target="_blank" rel="noreferrer">
      <DocumentationWrapper>
        <DocumentationIcon />
        <Typography variant="paragraphMedium" color="textColor.dark">
          Documentation
        </Typography>
      </DocumentationWrapper>
    </a>
  </>
);

const DocumentationWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "8px",
});

export default SubscriptionPlansLayout;
