import { Chip } from "@maestro-org/ui-kit";
import { styled } from "@mui/material";
import React from "react";

import { ArtistIcon, ComposerIcon, ConductorIcon, VirtuosoIcon } from "../Icons";
import { SubscriptionPlan } from "../../types/subscription";

interface Props {
  plan: SubscriptionPlan;
  textColor?: string;
}

const getLabel = {
  [SubscriptionPlan.artist]: "Artist",
  [SubscriptionPlan.composer]: "Composer",
  [SubscriptionPlan.conductor]: "Conductor",
  [SubscriptionPlan.virtuoso]: "Virtuoso",
};

const getIcon = {
  [SubscriptionPlan.artist]: <ArtistIcon />,
  [SubscriptionPlan.composer]: <ComposerIcon />,
  [SubscriptionPlan.conductor]: <ConductorIcon />,
  [SubscriptionPlan.virtuoso]: <VirtuosoIcon />,
};

const PlanChip = ({ plan, textColor }: Props): JSX.Element => {
  return (
    <StyledChip
      textColor={textColor || "#0F0311"}
      color="custom"
      hexColor="#D166E0"
      label={
        <>
          {getIcon[plan]}
          {getLabel[plan]}
        </>
      }
    />
  );
};

const StyledChip = styled(Chip)<{ textColor: string }>(({ theme, textColor }) => ({
  borderRadius: "43px",
  background: theme.palette.grey[50],
  padding: "8px 16px",
  height: "auto",

  "&:hover": {
    background: theme.palette.grey[50],
  },

  "& .MuiChip-label": {
    color: textColor,
    fontSize: `${theme.typography.h6.fontSize} !important`,
    fontWeight: `${theme.typography.h6.fontWeight} !important`,
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    columnGap: "8px",

    "& svg": {
      width: "20px",
      height: "20px",
    },

    "& path": {
      fill: theme.palette.primary.main,
    },
  },

  "& svg": {
    display: "inline-flex",
  },
}));

export default PlanChip;
