import React from "react";
import { useSelector } from "react-redux";
import { Button } from "@maestro-org/ui-kit";
import { styled, Typography } from "@mui/material";

import { getSubscriptionPlan } from "../../../../redux/reducers/authReducer";

import Divider from "../../../../components/Divider/Divider";

import { SubscriptionPlan } from "../../../../types/subscription";
import { UserSubscriptions } from "../../../../types/user";
import { CheckoutSteps } from "../../../../types/settings";

interface Props {
  handleChangeStep: (step?: CheckoutSteps) => void;
  activePlan?: UserSubscriptions;
}

const OrderSummaryMobile = ({ handleChangeStep, activePlan }: Props) => {
  const subscriptionPlan = useSelector(getSubscriptionPlan);

  return (
    <Wrapper>
      <Typography color="grey.A200" variant="h6">
        Order summary
      </Typography>
      <PlanInfoWrapper>
        <PlanInfoItem>
          {activePlan && !!+activePlan.price_per_credit ? (
            <Row>
              <Typography color="primary.main" variant="h6">
                Price per credit
              </Typography>
              <Typography color="primary.main" variant="h6">
                ${+activePlan.price_per_credit}
              </Typography>
            </Row>
          ) : (
            <Row>
              <Typography color="primary.main" variant="h6">
                Price per month
              </Typography>
              <Typography color="primary.main" variant="h6">
                {activePlan?.subscription_name === SubscriptionPlan.virtuoso
                  ? "Contact us"
                  : `$${activePlan && activePlan?.monthly_fee ? +activePlan.monthly_fee : ""}`}
              </Typography>
            </Row>
          )}
          <Row>
            <Typography color="grey.A200" variant="paragraphSmall">
              API Package
            </Typography>
            <PlanName color="grey.A200" variant="paragraphSmall">
              {activePlan?.subscription_name}
            </PlanName>
          </Row>
          {activePlan &&
            (activePlan.daily_credit_limit === "0" ? (
              <Row>
                <Typography color="grey.A200" variant="paragraphSmall">
                  Pay-as-you-go
                </Typography>
                <Typography color="grey.A200" variant="paragraphSmall">
                  Unlimited
                </Typography>
              </Row>
            ) : (
              <Row>
                <Typography color="grey.A200" variant="paragraphSmall">
                  Total compute credits
                </Typography>
                <Typography color="grey.A200" variant="paragraphSmall">
                  {+activePlan?.daily_credit_limit ? `${Math.floor(+activePlan.daily_credit_limit / 1000)}k/day` : ""}
                </Typography>
              </Row>
            ))}
        </PlanInfoItem>
        <Divider />
      </PlanInfoWrapper>
      <ButtonWrapper>
        {activePlan?.subscription_name === SubscriptionPlan.virtuoso ? (
          <Ref href="mailto:info@gomaestro.org">
            <Typography color="primary.main" variant="h5">
              Contact us
            </Typography>
          </Ref>
        ) : (
          <Actions>
            <BackButton onClick={() => handleChangeStep()}>Back</BackButton>
            <Button
              disabled={subscriptionPlan === activePlan?.subscription_name}
              onClick={() => handleChangeStep(CheckoutSteps.checkout)}
            >
              Checkout
            </Button>
          </Actions>
        )}
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "32px",
});

const PlanInfoWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "32px",
});

const PlanInfoItem = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
});

const Row = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const PlanName = styled(Typography)({
  textTransform: "capitalize",
});

const ButtonWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const Actions = styled("div")({
  display: "flex",
  justifyContent: "space-between",

  "& > button": {
    width: "calc(50% - 16px)",
  },
});

const BackButton = styled(Button)(({ theme }) => ({
  background: theme.palette.grey["50"],
  color: theme.palette.grey.A200,

  "&:hover": {
    background: theme.palette.grey["50"],
    color: theme.palette.grey.A200,
  },
}));

const Ref = styled("a")(({ theme }) => ({
  textDecoration: "underline",
  color: theme.palette.primary.main,
}));

const BottomInfo = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  columnGap: "12px",
});

export default OrderSummaryMobile;
