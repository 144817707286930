import { AccountDetailsFields } from "./types";

export const initialValuesAccountDetails = {
  [AccountDetailsFields.firstName]: "",
  [AccountDetailsFields.lastName]: "",
  [AccountDetailsFields.email]: "",
};

export const getAccountDetailsFields = [
  {
    type: "text",
    name: AccountDetailsFields.firstName,
    label: "First name",
    placeholder: "Enter your first name",
    fullWidth: false,
  },
  {
    type: "text",
    name: AccountDetailsFields.lastName,
    label: "Last name",
    placeholder: "Enter your second name",
    fullWidth: false,
  },
  {
    type: "email",
    name: AccountDetailsFields.email,
    label: "Email address",
    placeholder: "Enter email",
    fullWidth: true,
  },
];
