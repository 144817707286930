import { styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getSubscriptionServices, getUser, getUserSubscriptions } from "../../redux/reducers/usersReducer";
import {
  getBillingInvoicesServer,
  getSubscriptionServicesServer,
  getUserServer,
  getUserSubscriptionsServer,
} from "../../redux/actions/userActions";
import { getIsAuthorized, getSubscriptionPlan } from "../../redux/reducers/authReducer";

import SubscriptionPlansLayout from "../../layouts/SubscriptionPlansLayout";

import APIPackages from "./components/APIPackages";
import Addons from "./components/Addons";

import { SettingsPages } from "../../types/settings";
import { UserSubscriptions } from "../../types/user";
import withPrivateRoute from "../../hooks/withPrivateRoute";
import { updateSubscriptionPlan } from "../../redux/actions/authActions";
import { getProjectsServer } from "../../redux/actions/projectsActions";
import { getWebhooksServer } from "../../redux/actions/webhooksActions";
import { SubscriptionPlan } from "../../types/subscription";

const path = [
  { title: "Settings", href: "/" },
  { title: "Subscription", href: "/" },
  { title: "Plans", href: "/" },
];

const Plans = () => {
  const [activePlan, setActivePlan] = useState<UserSubscriptions>();

  const dispatch = useDispatch();

  const userSubscriptions = useSelector(getUserSubscriptions);
  const user = useSelector(getUser);
  const subscriptionServices = useSelector(getSubscriptionServices);
  const subscriptionPlan = useSelector(getSubscriptionPlan);
  const isAuthorized = useSelector(getIsAuthorized);

  const handleChangeActivePlan = (planId: string) => {
    const current = userSubscriptions.find(({ id }) => planId === id);
    if (!current) return;
    setActivePlan(current);
  };

  useEffect(() => {
    if (!userSubscriptions.length) return;
    const currentPlan = userSubscriptions.find((item) => item.subscription_name === subscriptionPlan);
    if (!currentPlan) {
      setActivePlan(userSubscriptions[0]);
      return;
    }
    setActivePlan(currentPlan);
  }, [userSubscriptions, subscriptionPlan]);

  useEffect(() => {
    if (!isAuthorized) return;
    dispatch(getUserSubscriptionsServer());
    dispatch(getSubscriptionServicesServer());
    dispatch(getUserServer());
    dispatch(getBillingInvoicesServer());
    dispatch(getProjectsServer({}));
    dispatch(getWebhooksServer({}));
  }, [isAuthorized]);

  useEffect(() => {
    if (!user || !userSubscriptions.length) return;
    const currentSubscription = userSubscriptions.find((item) => item.id === user.subscription_id);
    dispatch(updateSubscriptionPlan({ plan: currentSubscription?.subscription_name || SubscriptionPlan.artist }));
  }, [user, userSubscriptions]);

  return (
    <SubscriptionPlansLayout path={path} active={SettingsPages.plans} activePlan={activePlan}>
      <Wrapper>
        <APIPackages activePlan={activePlan} handleChangeActive={handleChangeActivePlan} packages={userSubscriptions} />
        <Addons activePlan={activePlan} services={subscriptionServices} />
        {/* <ScalingPolicy
          packages={packages}
          activePlan={activePlan}
          activeScalingPolicy={activeScalingPolicy}
          handleChangeActive={handleChangeScalingPolicy}
          scalingPolicyCards={scalingPolicyCards}
        /> */}
      </Wrapper>
    </SubscriptionPlansLayout>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "64px",
  paddingBottom: "108px",
});

// export interface Package {
//   name: SubscriptionPlan;
//   price: number | string;
//   credits: number | string;
//   indexerAPI: string[];
//   addons: AddonsType[];
//   included: AddonsType[];
//   // scalingPolicy: ScalingPolicyType[];
//   isMostPopular: boolean;
// }

// const packages: Package[] = [
//   {
//     name: SubscriptionPlan.artist,
//     price: "Free",
//     credits: 100000,
//     indexerAPI: ["1 project", "1 webhook"],
//     addons: [AddonsType.transactionMonitoring, AddonsType.transactionWebhooks, AddonsType.projectMetrics],
//     included: [AddonsType.transactionMonitoring, AddonsType.transactionWebhooks, AddonsType.projectMetrics],
//     // scalingPolicy: [ScalingPolicyType.cappedCapacity],
//     isMostPopular: false,
//   },
//   {
//     name: SubscriptionPlan.composer,
//     price: 9,
//     credits: 200000,
//     indexerAPI: ["3 projects", "3 webhook", "2x throughput limit"],
//     addons: [
//       AddonsType.transactionMonitoring,
//       AddonsType.transactionWebhooks,
//       AddonsType.projectMetrics,
//       AddonsType.turboTransaction,
//     ],
//     included: [AddonsType.everythingFromArtist, AddonsType.turboTransaction],
//     // scalingPolicy: [ScalingPolicyType.cappedCapacity, ScalingPolicyType.boostCapacity],
//     isMostPopular: true,
//   },
//   {
//     name: SubscriptionPlan.conductor,
//     price: 0.00006,
//     credits: "Unlimited",
//     indexerAPI: ["5 projects", "5 webhook", "5x throughput limit"],
//     addons: [
//       AddonsType.transactionMonitoring,
//       AddonsType.transactionWebhooks,
//       AddonsType.projectMetrics,
//       AddonsType.turboTransaction,
//     ],
//     included: [AddonsType.everythingFromComposer],
//     // scalingPolicy: [ScalingPolicyType.cappedCapacity, ScalingPolicyType.boostCapacity, ScalingPolicyType.autoScale],
//     isMostPopular: false,
//   },
//   {
//     name: SubscriptionPlan.virtuoso,
//     price: "Contact us",
//     credits: "Unlimited",
//     indexerAPI: ["Unlimited projects", "Unlimited webhooks", "Custom throughput limit"],
//     addons: [
//       AddonsType.transactionMonitoring,
//       AddonsType.transactionWebhooks,
//       AddonsType.projectMetrics,
//       AddonsType.turboTransaction,
//     ],
//     included: [AddonsType.everythingFromComposer],
//     // scalingPolicy: [ScalingPolicyType.cappedCapacity, ScalingPolicyType.boostCapacity, ScalingPolicyType.autoScale],
//     isMostPopular: false,
//   },
// ];

export default withPrivateRoute(Plans);
