import { IconButton, Radio, TextField } from "@maestro-org/ui-kit";
import { Collapse, styled, Typography } from "@mui/material";
import React from "react";

import Divider from "../../../components/Divider/Divider";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { DropdownIcon, LockIcon } from "../../../components/Icons";

export enum CardTypes {
  Expand,
  Radio,
  Multiplier,
}

interface MultiplierCardProps {
  multiplierValue?: number;
  handleMultiplierChangeUp?: (event: React.MouseEvent<HTMLElement>) => void;
  handleMultiplierChangeDown?: (event: React.MouseEvent<HTMLElement>) => void;
  handleMultiplierInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface CardProps extends MultiplierCardProps {
  title: string;
  subtitle?: string;
  description: string[];
  type: CardTypes;
  disabled?: boolean;
  availableIn?: string[];
  selected?: boolean;
  handleSelectedChange?: (title?: string) => void;
  expanded?: boolean;
  icon?: React.ReactNode;
}

const PlanSettingsCard = ({
  title,
  subtitle,
  description,
  type,
  disabled,
  availableIn,
  selected,
  multiplierValue,
  handleMultiplierChangeUp,
  handleMultiplierChangeDown,
  handleSelectedChange,
  handleMultiplierInputChange,
  expanded,
  icon,
}: CardProps) => {
  const getCardActions = {
    [CardTypes.Expand]: <DropdownIconWrapper expanded={expanded}>{/* <DropdownIcon /> */}</DropdownIconWrapper>,
    [CardTypes.Radio]: (
      <RadioWrapper>
        <StyledRadio disabled={disabled} checked={selected} value="" label="" handleChange={console.log} />
      </RadioWrapper>
    ),
    [CardTypes.Multiplier]: (
      <MultiplierWrapper value={multiplierValue}>
        <StyledIconButton disabled={disabled} onClick={handleMultiplierChangeDown}>
          -
        </StyledIconButton>
        <TextField
          onChange={handleMultiplierInputChange}
          disabled={disabled}
          type="number"
          variant="outlined"
          value={multiplierValue}
          onClick={(event) => event.stopPropagation()}
        />
        <StyledIconButton disabled={disabled} onClick={handleMultiplierChangeUp}>
          +
        </StyledIconButton>
      </MultiplierWrapper>
    ),
  };

  return (
    <StyledTooltip
      disabled={disabled}
      title={
        <>
          <LockIcon />
          <Typography variant="article" color="grey.800">
            Available in{" "}
            {availableIn?.reduce(
              (acc, item, index) =>
                (acc += `${item}${
                  index !== availableIn.length - 1 ? (index === availableIn.length - 2 ? " and " : ", ") : ""
                }`),
              "",
            )}
          </Typography>
        </>
      }
      followCursor
      placement="top"
    >
      <Wrapper isActive={selected && !disabled}>
        <CardHeader disabled={disabled}>
          <Top>
            <TitleWrapper disabled={disabled}>
              {icon}
              <Typography color={disabled ? "grey.300" : "grey.A200"} variant="h5">
                {title}
              </Typography>
            </TitleWrapper>
            {subtitle && (
              <Typography color={disabled ? "grey.300" : "grey.A200"} variant="h6">
                {typeof subtitle === "string" ? subtitle : `+ $${subtitle}/mo`}
              </Typography>
            )}
          </Top>
          {getCardActions[type]}
        </CardHeader>
        <Collapse in={expanded === undefined ? true : expanded}>
          <CollapseWrapper canBeExpanded={expanded !== undefined}>
            <DividerWrapper>
              <Divider />
            </DividerWrapper>
            <DescriptionWrapper>
              {description.map((item) => (
                <StyledListItem key={item} disabled={disabled}>
                  <Typography color={disabled ? "grey.300" : "grey.A200"} variant="paragraphMedium">
                    {item}
                  </Typography>
                </StyledListItem>
              ))}
            </DescriptionWrapper>
          </CollapseWrapper>
        </Collapse>
      </Wrapper>
    </StyledTooltip>
  );
};

const Wrapper = styled("div")<{ isActive?: boolean }>(({ theme, isActive }) => ({
  borderRadius: theme.borderRadius.sm,
  boxShadow: "0px 2px 12px rgba(153, 153, 153, 0.12)",
  background: theme.palette.common.white,
  paddingBottom: "7px",
  display: "flex",
  flexDirection: "column",
  border: isActive ? `2px solid ${theme.palette.primary.main}` : `2px solid ${theme.palette.grey[100]}`,
}));

const DropdownIconWrapper = styled("div")<{ expanded?: boolean }>(({ expanded }) => ({
  "& svg": {
    transition: "0.3s",
    transform: `rotateZ(${expanded ? "180deg" : "0deg"})`,
  },
}));

const CardHeader = styled("div")<{ disabled?: boolean }>(({ disabled }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  // cursor: disabled ? "default" : "pointer",
  padding: "32px 32px 25px",

  "& .MuiFormControlLabel-root": {
    margin: 0,
  },
}));

const StyledTooltip = styled(Tooltip)<{ disabled?: boolean }>(({ theme, disabled }) => ({
  ...(!disabled && { display: "none" }),
  "& .MuiTooltip-tooltip": {
    maxWidth: "236px",
    padding: "8px 16px",
    boxShadow: "0px 2px 16px -2px rgba(15, 3, 17, 0.16)",
    border: `1px solid ${theme.palette.grey[100]}`,
    display: "flex",
    alignItems: "center",
    columnGap: "9px",
  },

  "& svg": {
    width: "13px",
    height: "16px",
  },
}));

const Top = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
});

const TitleWrapper = styled("div")<{ disabled?: boolean }>(({ theme, disabled }) => ({
  display: "flex",
  alignItems: "center",
  columnGap: "20px",

  "& svg": {
    width: "32px",
    height: "32px",
  },
  "& svg path": {
    fill: disabled ? theme.palette.grey[300] : theme.palette.primary.main,
  },
}));

const CollapseWrapper = styled("div")<{ canBeExpanded: boolean }>(({ canBeExpanded, theme }) => ({
  // height: canBeExpanded ? "102px" : "auto",
  padding: "0 32px 25px",

  [theme.breakpoints.down("xl")]: {
    height: canBeExpanded ? "142px" : "auto",
  },
}));

const DividerWrapper = styled("div")({
  paddingBottom: "25px",
});

const DescriptionWrapper = styled("ul")({
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
});

const StyledListItem = styled("li")<{ disabled?: boolean }>(({ disabled, theme }) => ({
  marginLeft: "12px",
  paddingLeft: "5px",

  "&::marker": {
    content: '"✓"',
    color: disabled ? theme.palette.grey[300] : theme.palette.grey.A200,
  },
}));

const RadioWrapper = styled("div")({
  "& > .Mui-disabled": {
    pointerEvents: "none",
  },
});

const StyledRadio = styled(Radio)(({ disabled }) => ({
  padding: 0,
  pointerEvents: disabled ? "none" : "auto",
}));

const MultiplierWrapper = styled("div")<{ value?: number }>(({ value }) => ({
  display: "flex",
  alignItems: "center",

  "& .MuiInputBase-root": {
    padding: "6px 12.5px !important",
    width: value && value > 9 ? "43px" : "35px",
    maxHeight: "32px",
  },

  "& > div": {
    rowGap: 0,
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px",
  },

  "& .MuiInputBase-input::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  background: "transparent",
  color: theme.palette.grey.A200,
  ...theme.typography.paragraphSmall,
  width: "32px",
  height: "32px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 0,

  "&:hover": {
    background: "transparent",
    color: theme.palette.grey.A200,
  },
}));

export default PlanSettingsCard;
