import { useEffect } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { CircularProgress, styled, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Auth0Error } from "auth0-js";

import { initialValuesLogin, loginFields } from "../../forms/login/form";
import { LoginFormValues } from "../../forms/login/types";
import { loginSchema } from "../../forms/login/validation";

import { updateIsLoadingAuth } from "../../redux/actions/authActions";
import { getAuthLoading } from "../../redux/reducers/authReducer";

import AuthLayout from "../../layouts/AuthLayout";

import { Button } from "@maestro-org/ui-kit";
import Divider from "../../components/Divider/Divider";
import TextField from "./components/TextField";
import { GithubIcon, GoogleIcon } from "../../components/Icons";

import { auth } from "../../services/auth0.service";
import { AUTH0_LOGIN_REDIRECT_URI } from "../../config";
import withAuthRedirect from "../../hooks/withAuthRedirect";

const LoginPage = () => {
  const isLoading = useSelector(getAuthLoading);

  const dispatch = useDispatch();

  const onSubmit = (values: LoginFormValues) => {
    dispatch(updateIsLoadingAuth({ status: true }));
    auth.login(
      {
        email: values.email,
        password: values.password,
        realm: process.env.REACT_APP_AUTH0_REALM,
        redirectUri: AUTH0_LOGIN_REDIRECT_URI,
        responseType: process.env.REACT_APP_AUTH0_LOGIN_RESPONSE_TYPE,
      },
      function (error: Auth0Error | null, result: any) {
        dispatch(updateIsLoadingAuth({ status: false }));
        if (error) {
          console.log("LOGIN ERROR", error);
          toast.error(error.description);
          return;
        }
        toast.success("Successfully logged in!");
        console.log("LOGIN SUCCESS", result);
      },
    );
  };

  const formik = useFormik({
    initialValues: initialValuesLogin,
    validationSchema: loginSchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit,
  });

  useEffect(() => {
    dispatch(updateIsLoadingAuth({ status: false }));
  }, []);

  const handleLoginWithGoogle = () => {
    auth.authorize({
      domain: `${process.env.REACT_APP_AUTH0_DOMAIN}`,
      connection: "google-oauth2",
      responseType: process.env.REACT_APP_AUTH0_LOGIN_RESPONSE_TYPE,
      mode: "login",
      redirectUri: AUTH0_LOGIN_REDIRECT_URI,
    });
  };

  const handleLoginWithGithub = () => {
    auth.authorize({
      domain: `${process.env.REACT_APP_AUTH0_DOMAIN}`,
      connection: "github",
      responseType: process.env.REACT_APP_AUTH0_LOGIN_RESPONSE_TYPE,
      mode: "login",
      redirectUri: AUTH0_LOGIN_REDIRECT_URI,
    });
  };

  return (
    <AuthLayout title="The developer platform for Cardano">
      <form onSubmit={formik.handleSubmit}>
        <Wrapper>
          <Top>
            <StyledTitle color="textColor.dark" variant="h2">
              Login
            </StyledTitle>
            <Typography color="textColor.dark" variant="paragraphMedium">
              Don&apos;t have an account? <Ref to="/signup">Sign Up</Ref>
            </Typography>
          </Top>
          <FieldsWrapper>
            {loginFields.map((field) => (
              <TextField key={field.name} field={field} {...formik} />
            ))}
            <ForgotPass color="textColor.dark" variant="paragraphMedium">
              <Ref to="/reset-password">Forgot Password?</Ref>
            </ForgotPass>
          </FieldsWrapper>
          <Button
            variant="primary"
            size="medium"
            type="submit"
            onMouseDown={(e) => e.preventDefault()}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress /> : "Log in"}
          </Button>
          <DividerWrapper>
            <Divider color="light" />
            <DividerText color="textColor.dark" variant="article">
              OR
            </DividerText>
          </DividerWrapper>
          <Actions>
            <GoogleButton size="medium" variant="primary" startIcon={<GoogleIcon />} onClick={handleLoginWithGoogle}>
              Login with Google
            </GoogleButton>
            <Button size="medium" variant="secondary" startIcon={<GithubIcon />} onClick={handleLoginWithGithub}>
              Login with GitHub
            </Button>
          </Actions>
        </Wrapper>
      </form>
    </AuthLayout>
  );
};

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 300,
  fontSize: "40px",
  lineHeight: "56px",
}));

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "40px",
});

const Top = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
});

const Ref = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: "pointer",
  textDecoration: "underline",
}));

const FieldsWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "16px",
});

const ForgotPass = styled(Typography)({
  textDecoration: "underline",
});

const DividerWrapper = styled("div")({
  position: "relative",
});

const DividerText = styled(Typography)(({ theme }) => ({
  padding: "0 20px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.common.white,
}));

const Actions = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "20px",
});

const GoogleButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  borderColor: theme.palette.grey[50],
  color: theme.palette.grey["A200"],

  "&:hover": {
    backgroundColor: "#D6D6D6",
  },

  "&:disabled": {
    backgroundColor: theme.palette.grey[50],
  },
}));

export default withAuthRedirect(LoginPage);
