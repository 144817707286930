import { Button } from "@maestro-org/ui-kit";
import { styled, Typography } from "@mui/material";
import React from "react";

import { InfoIcon } from "../../../../components/Icons";
import { useSelector } from "react-redux";
import { getAllPaymentMethods } from "../../../../redux/reducers/stripeReducer";
import { getUserSubscriptions } from "../../../../redux/reducers/usersReducer";
import { getAllprojects } from "../../../../redux/reducers/projectsReducer";
import { getAllWebhooks } from "../../../../redux/reducers/webhooksReducer";
import { UserSubscriptions } from "../../../../types/user";

interface Props {
  handleBackClick: () => void;
  activePlan?: UserSubscriptions;
}

const PaymentFailMobile = ({ handleBackClick, activePlan }: Props) => {
  const allSubscription = useSelector(getUserSubscriptions);
  const allProjects = useSelector(getAllprojects);
  const allWebhooks = useSelector(getAllWebhooks);

  const currentSubscription = allSubscription.find((item) => activePlan?.subscription_name === item.subscription_name);

  const projectsLimit =
    currentSubscription && +currentSubscription.project_limit ? +currentSubscription.project_limit : 1;
  const webhooksLimit =
    currentSubscription && +currentSubscription.webhook_limit ? +currentSubscription.webhook_limit : 1;

  const getError = () => {
    if (projectsLimit < allProjects.length) {
      return { title: "User has too many projects", subtitle: "Please check your payment and billing and try again." };
    }
    if (webhooksLimit < allWebhooks.length) {
      return { title: "User has too many webhooks", subtitle: "Please check your payment and billing and try again." };
    }
    return { title: "Insufficent funds", subtitle: "Please check your payment and billing and try again." };
  };

  const error = getError();

  return (
    <Wrapper>
      <Top>
        <TitleWrapper>
          <InfoIcon />
          <Typography variant="h5" color="grey.A200">
            {error.title}
          </Typography>
        </TitleWrapper>
        <Typography color="grey.A200" variant="paragraphSmall">
          {error.subtitle}
        </Typography>
      </Top>
      {/* <Row>
        <AmountToPay color="error.main" variant="paragraphMedium">
          Amount to pay
        </AmountToPay>
        <Typography color="error.main" variant="paragraphSmall">
          $ 144.00
        </Typography>
      </Row> */}
      <Button onClick={handleBackClick}>Check Payment Details</Button>
    </Wrapper>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "40px",
});

const Top = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "20px",
});

const TitleWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  columnGap: "12px",

  "& svg": {
    width: "21px",
    height: "21px",
  },

  "& path": {
    stroke: theme.palette.error.main,
  },
}));

const Row = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const AmountToPay = styled(Typography)({
  fontWeight: 500,
});

export default PaymentFailMobile;
