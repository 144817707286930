import { Button } from "@maestro-org/ui-kit";
import { styled, Typography } from "@mui/material";
import React, { useEffect } from "react";

import Loader from "../Loader";
import { InfoIcon } from "../../../../components/Icons";

import { CheckoutSteps } from "../../../../types/settings";

interface Props {
  handleChangeStep: (step?: CheckoutSteps) => void;
}

const PaymentInProcessMobile = ({ handleChangeStep }: Props) => {
  return (
    <Wrapper>
      <Typography color="grey.A200" variant="h5">
        Waiting for payment
      </Typography>
      <LoaderWrapper>
        <Loader />
        <Typography color="grey.A200" variant="paragraphSmall">
          Payment is processing...
          <br />
          Please wait, do not close this screen.
        </Typography>
      </LoaderWrapper>
      <Bottom>
        {/* <BottomInfo>
          <InfoIcon />
          <Typography color="grey.300" variant="article">
            You will be forwarded to our payment processor’s gateway
          </Typography>
        </BottomInfo> */}
        <Button disabled>
          <Typography variant="paragraphSmall">Payment in process</Typography>
        </Button>
      </Bottom>
    </Wrapper>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  rowGap: "40px",
});

const LoaderWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "16px",
  alignItems: "center",
  textAlign: "center",
});

const Bottom = styled("div")({
  paddingTop: "16px",
  display: "flex",
  flexDirection: "column",
  rowGap: "24px",
  width: "100%",

  "& button": {
    width: "100%",
  },
});

const BottomInfo = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  columnGap: "12px",
});

export default PaymentInProcessMobile;
