import { styled } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import { getAuthData } from "../../redux/reducers/authReducer";

interface Props {
  size: number;
}

const UserImage = ({ size }: Props) => {
  const userData = useSelector(getAuthData);

  const picture = userData.picture;
  const firstName = userData.firstName;
  // const lastName = userData.lastName;

  return picture ? (
    <Image src={picture} size={size} />
  ) : (
    <NoImageWrapper size={size}>{firstName ? firstName[0] : ""}</NoImageWrapper>
  );
};

const Image = styled("img")<{ size: number }>(({ theme, size }) => ({
  borderRadius: "50%",
  width: `${size}px`,
  height: `${size}px`,
  objectFit: "cover",
  border: `2px solid ${theme.palette.grey[100]}`,
}));

const NoImageWrapper = styled("div")<{ size: number }>(({ theme, size }) => ({
  background: theme.palette.grey[100],
  borderRadius: "50%",
  width: `${size}px`,
  height: `${size}px`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textTransform: "uppercase",
  fontWeight: 500,
  fontSize: `${(size * 43) / 80}px`,
  lineHeight: `${(size * 65) / 80}px`,
  color: theme.palette.grey.A200,
}));

export default UserImage;
