export const TokenIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 0.5H6.5C2.91 0.5 0 3.41 0 7C0 10.59 2.91 13.5 6.5 13.5H7.5C11.09 13.5 14 10.59 14 7C14 3.41 11.09 0.5 7.5 0.5ZM6.5 12.5C3.47 12.5 1 10.03 1 7C1 3.97 3.47 1.5 6.5 1.5C9.53 1.5 12 3.97 12 7C12 10.03 9.53 12.5 6.5 12.5Z"
      fill="#B500FF"
    />
    <path
      d="M4.9346 8.43667C5.93614 7.41833 6.50691 6.0425 6.50691 4.60167V2.5H5.42999V5.75H2.19922V6.83333H4.89153L2.73768 9L3.55614 9.82333L4.9346 8.43667Z"
      fill="#B500FF"
    />
    <path
      d="M8.07922 5.21917C7.07768 6.2375 6.50691 7.61333 6.50691 9.05417V11.1558H7.58383V7.90583H10.8146V6.8225H8.1223L10.2761 4.65583L9.45768 3.8325L8.07922 5.21917Z"
      fill="#B500FF"
    />
  </svg>
);
