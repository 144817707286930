import { NewEntryField, NewEntryFieldTypes } from "../../types/newEntry";
import { Network, networkLabels } from "../../types/project";
import { CreateProjectFields } from "./types";

export const initialValuesCreateProject = {
  [CreateProjectFields.name]: "",
  [CreateProjectFields.network]: [],
};

const networkOptions = Object.values(networkLabels);

export const getCreateProjectFields: NewEntryField[] = [
  {
    type: NewEntryFieldTypes.text,
    name: CreateProjectFields.name,
    label: "Project name",
    placeholder: "Enter project name",
  },
  {
    type: NewEntryFieldTypes.select,
    name: CreateProjectFields.network,
    label: "Network",
    placeholder: "Select network",
    options: networkOptions,
  },
];
