import React from "react";
import APIKeyCreatedDialog from "./APIKeyCreatedDialog";
import DeleteProjectDialog from "./DeleteProjectDialog";
import DeleteWebhookDialog from "./DeleteWebhookDialog";
import ResetKeyDialog from "./ResetKeyDialog";
import AddNewProjectDialog from "./AddNewProjectDialog";
import CreateWebhookDialog from "./CreateWebhookDialog";
import DeleteAccountDialog from "./DeleteAccountDialog";

const AllDialogs = () => (
  <>
    <APIKeyCreatedDialog />
    <ResetKeyDialog />
    <DeleteProjectDialog />
    <DeleteWebhookDialog />
    <AddNewProjectDialog />
    <CreateWebhookDialog />
    <DeleteAccountDialog />
  </>
);

export default AllDialogs;
