import React, { useEffect } from "react";
import { styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import SettingsLayout from "../../layouts/SettingsLayout";

import CurrentSubscriptionPlan from "./components/CurrentSubscriptionPlan";
import AvailableCards from "./components/AvailableCards";
import BillingInfo from "./components/BillingInfo";

import { getAllPaymentMethods, getBillingDataLoading } from "../../redux/reducers/stripeReducer";

import { SettingsPages } from "../../types/settings";
import withPrivateRoute from "../../hooks/withPrivateRoute";
import EmptyPaymentMethods from "./components/EmptyPaymentMethods";
import { getUser } from "../../redux/reducers/usersReducer";
import { getCustomerServer, setPaymentServer } from "../../redux/actions/stripeActions";

const SubscriptionPlanPage = () => {
  const paymentMethods = useSelector(getAllPaymentMethods);
  const isLoading = useSelector(getBillingDataLoading);
  const user = useSelector(getUser);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user && paymentMethods.length && !paymentMethods.some((item) => item.id === user.default_payment_method_id))
      dispatch(setPaymentServer({ methodId: paymentMethods[0].id }));
  }, [paymentMethods]);

  useEffect(() => {
    dispatch(getCustomerServer());
  }, []);

  return (
    <SettingsLayout
      path={[
        { title: "Settings", href: "/" },
        { title: "Subscription", href: "/" },
        { title: "Overview", href: "/" },
      ]}
      active={SettingsPages.overview}
    >
      <Wrapper>
        <CurrentSubscriptionPlan />
        {paymentMethods.length || isLoading ? (
          <>
            <AvailableCards />
            <BillingInfo />
          </>
        ) : (
          <EmptyPaymentMethods />
        )}
      </Wrapper>
    </SettingsLayout>
  );
};

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "32px",
}));

export default withPrivateRoute(SubscriptionPlanPage);
