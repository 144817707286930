import React, { useState } from "react";
import { styled, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { updatePopup } from "../../../redux/actions/popupsActions";

import { getResetKeysIds } from "../../../redux/reducers/projectsReducer";

import { KeyIcon, SuccessCopiedIcon, UrlIcon } from "../../../components/Icons";

import { Popups } from "../../../types/popups";
import { Network } from "../../../types/project";
import { copyToClipboard } from "../../../lib/copyToClipboard";

interface Props {
  projectId: string;
  copyKey: string;
  projectName: string;
  network: Network;
}

const getCopyUrl = {
  [Network.Mainnet]: process.env.REACT_APP_MAINNET_URL,
  [Network.Preprod]: process.env.REACT_APP_PREPROD_URL,
  [Network.Preview]: process.env.REACT_APP_PREVIEW_URL,
};

const ApiKeyTableCell = ({ projectId, projectName, network }: Props) => {
  const [copiedUrls, setCopiedUrls] = useState<string[]>([]);

  const resetKeys = useSelector(getResetKeysIds);

  const dispatch = useDispatch();

  const handleCopyUrl = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
    event.stopPropagation();
    copyToClipboard(`${getCopyUrl[network]}v${process.env.API_VERSION || 0}`);
    setCopiedUrls((ids) => [...ids, id]);
    setTimeout(() => {
      setCopiedUrls((ids) => ids.filter((projectId) => projectId !== id));
    }, 1500);
  };

  const handleCopyKey = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    dispatch(updatePopup({ popup: Popups.resetKey, status: true, prefilled: { projectName, projectId } }));
  };

  return (
    <Wrapper>
      <CopyItemWrapper onClick={(event) => handleCopyUrl(event, projectId)}>
        {copiedUrls.includes(projectId) ? (
          <WithIcon>
            <SuccessCopiedIcon />
            <Typography color="textColor.dark" variant="paragraphSmall">
              URL copied!
            </Typography>
          </WithIcon>
        ) : (
          <WithIcon>
            <CopyText color="textColor.contrastText" variant="paragraphSmall" className="hover-appear">
              Copy
            </CopyText>
            <CopyText color="textColor.contrastText" variant="paragraphSmall" className="hover-hide">
              URL
            </CopyText>
            <UrlIcon />
          </WithIcon>
        )}
      </CopyItemWrapper>
      <CopyItemWrapper onClick={handleCopyKey}>
        {resetKeys.includes(projectId) ? (
          <WithIcon>
            <SuccessCopiedIcon />
            <Typography color="textColor.dark" variant="paragraphSmall">
              Key Reset!
            </Typography>
          </WithIcon>
        ) : (
          <WithIcon>
            <CopyText color="textColor.contrastText" variant="paragraphSmall" className="hover-appear">
              Reset
            </CopyText>
            <CopyText color="textColor.contrastText" variant="paragraphSmall" className="hover-hide">
              Key
            </CopyText>
            <KeyIcon />
          </WithIcon>
        )}
      </CopyItemWrapper>
    </Wrapper>
  );
};

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "100%",
  columnGap: "24px",
  width: "190px",
  maxWidth: "100%",

  [theme.breakpoints.down("lg")]: {
    width: "auto",
  },
}));

const CopyText = styled(Typography)({
  minWidth: "35px",
});

const CopyItemWrapper = styled("div")({
  cursor: "pointer",

  "& .hover-appear": {
    display: "none",
  },
  "&:hover .hover-appear": {
    display: "flex",
  },
  "&:hover .hover-hide": {
    display: "none",
  },
});

const WithIcon = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "2px",
});

export default ApiKeyTableCell;
