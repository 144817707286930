export const typography = {
  fontFamily: ["Ranua", "sans-serif"].join(","),

  secondaryFont: ["Ranua", "sans-serif"].join(","),

  allVariants: {
    fontFamily: ["Ranua", "sans-serif"].join(","),
    color: "#ffffff",
  },

  h1: {
    fontWeight: 300,
    fontSize: "65px",
    lineHeight: "74px",
  },
  h2: {
    fontWeight: 300,
    fontSize: "48px",
    lineHeight: "56.8px",
  },
  h3: {
    fontWeight: 300,
    fontSize: "40px",
    lineHeight: "48px",
  },
  h5: {
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "28.8px",
  },
  subtitle1: {
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "32px",
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19.2px",
  },
  subtitle3: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14.4px",
  },
  subtitle4: {
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "24px",
  },
  caption: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "36px",
  },
  caption2: {
    fontWeight: 300,
    fontSize: "20px",
    lineHeight: "36px",
  },
  textfieldLabel: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
  },
  authTitle: {
    fontWeight: 300,
    fontSize: "64px",
    lineHeight: "80px",
  },
  dividerText: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
  },
  authLowerText: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "13.44px",
    letterSpacing: "-2.5%",
  },
  footer1: {
    fontWeight: 400,
    fontSize: "28px",
    lineHeight: "33.6px",
  },
  footer2: {
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "21.6px",
  },
  footer3: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "13.44px",
    letterSpacing: "-2.5%",
  },
  footer4: {
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "19.2px",
  },
  footer5: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "15.36px",
  },
  tableText: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
  },
  chartTitle: {
    fontWeight: 400,
    fontSize: "28px",
    lineHeight: "32px",
  },
  dateSwitch: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
  },
  tooltipText: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
  },
};
