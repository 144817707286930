import { Button, Chip } from "@maestro-org/ui-kit";
import { styled, Typography } from "@mui/material";
import React from "react";

import { EntryType } from "../../types/table";

interface Props {
  page: number;
  rowsPerPage: number;
  entries: EntryType[];
  handlePrevPage: () => void;
  handleNextPage: () => void;
  handlePageChenge: (index: number) => void;
}

const TablePagination = ({ page, rowsPerPage, entries, handleNextPage, handlePrevPage, handlePageChenge }: Props) => {
  const allPages = Math.ceil(entries.length / rowsPerPage);

  const results = { num: 1 + page * rowsPerPage, of: allPages > page + 1 ? rowsPerPage * (page + 1) : entries.length };

  if (rowsPerPage + 1 > entries.length || !entries.length) return <div />;

  return (
    <Bottom>
      <Typography variant="paragraphSmall" color="textColor.dark">
        Results {results.num}-{results.of} of {entries.length}
      </Typography>
      <PaginationWrapper>
        <PaginationButton onClick={handlePrevPage} disabled={page === 0}>
          <Typography variant="paragraphSmall" color="textColor.dark">
            Previous
          </Typography>
        </PaginationButton>
        <ChipsWrapper>
          {Array.from(Array(allPages).keys()).map((i, index) => (
            <StyledChip
              key={index}
              color={index === page ? "primary" : "default"}
              label={index + 1}
              onClick={() => handlePageChenge(index)}
            />
          ))}
        </ChipsWrapper>
        <PaginationButton onClick={handleNextPage} disabled={page === allPages - 1}>
          <Typography variant="paragraphSmall" color="textColor.dark">
            Next
          </Typography>
        </PaginationButton>
      </PaginationWrapper>
    </Bottom>
  );
};

const Bottom = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const PaginationWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "20px",
});

const PaginationButton = styled(Button)({
  background: "transparent",
  padding: "5px 5px",
  "&: hover": {
    background: "transparent",
  },

  "&.Mui-disabled": {
    background: "transparent",
    opacity: "0.7",
  },
});

const ChipsWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "12px",
});

const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.borderRadius.xxs,
  width: "24px",
  height: "24px",
  padding: 0,

  "& .MuiChip-label": {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
  },
}));

export default TablePagination;
